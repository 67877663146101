import axiosWrapper from "../services/axiosWrapper";
import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from "../services/constants";
import { showToastNotification } from "./toast";

export function setFileVersions(data, fileId) {
  return {
    type: "SET_FILE_VERSION_DATA",
    data: {
      data,
      fileId,
    },
  };
}

export function setIsFileVersionUploading(data) {
  return {
    type: "IS_FILE_VERSION_UPLOADING",
    data,
  };
}

export function setCurrentFileUploadProperties(data) {
  return {
    type: "SET_CURRENT_FILE_UPLOAD_PROPERTIES",
    data,
  };
}

export function setFileUploadProperties(data) {
  return {
    type: "SET_FILE_UPLOAD_PROPERTIES",
    data,
  };
}

export function minimiseFileUpload(data) {
  return {
    type: "MINIMISED",
    data: "MINIMISED",
  };
}

export function maximiseFileUpload(data) {
  return {
    type: "MAXIMISED",
    data: "MAXIMISED",
  };
}

export function closeFileUpload(data) {
  return {
    type: "CLOSED",
    data: "CLOSED",
  };
}

export function setFilesData(data) {
  return {
    type: "SET_FILES_DATA",
    data,
  };
}

export function resetFilesData() {
  return {
    type: "RESET_FILES_DATA",
  };
}

export function setFileUploadData(data) {
  return {
    type: "SET_FILE_UPLOAD_DATA",
    data,
  };
}

export function removeFileUploadData(data) {
  return {
    type: "REMOVE_FILE_UPLOAD_DATA",
    data,
  };
}

export function removeAllFileUploadData() {
  return {
    type: "REMOVE_ALL_FILE_UPLOAD_DATA",
  };
}

export function updateFileUploadData(data) {
  return {
    type: "UPDATE_FILE_UPLOAD_DATA",
    data,
  };
}

export function emptyFileUploadData() {
  return {
    type: "EMPTY_FILE_UPLOAD_DATA",
  };
}

export function locationRemove(data) {
  return {
    type: "LOCATION_REMOVE",
    data,
  };
}

export function documentTypeRemove(data) {
  return {
    type: "DOCUMENT_REMOVE",
    data,
  };
}

export function locationChange(data) {
  return {
    type: "LOCATION_CHANGE",
    data,
  };
}

export function documentTypeChange(data) {
  return {
    type: "DOCUMENT_CHANGE",
    data,
  };
}

export function setFileDependencies(data) {
  return {
    type: "SET_FILE_DEPENDENCIES",
    data,
  };
}

export function setResourcesUnderWhichFilesShared(data, fileId) {
  return {
    type: "SET_RESOURCES_UNDER_WHICH_FILES_SHARED",
    data,
    fileId,
  };
}

export function setFileVersionsStatus(data) {
  return {
    type: "SET_FILE_VERSION_STATUS",
    data,
  };
}

export function mergeFileVersions(data) {
  return {
    type: "MERGE_FILE_VERSION_DATA",
    data,
  };
}

export function refreshFileManager(data) {
  return {
    type: "REFRESH_FILE_MANAGER",
    data,
  };
}
export function selectedFilesFileManager(data) {
  return {
    type: "SET_SELECTED_FILE",
    data,
  };
}

export function setCurrentFolderInFileManager(data) {
  return {
    type: "SET_CURRENT_FOLDER",
    data,
  };
}

export function emptyCurrentFolderInFileManager(data) {
  return {
    type: "EMPTY_CURRENT_FOLDER",
    data,
  };
}

export function fileManagerBreadCrumb(data) {
  return {
    type: "SET_FILE_MANAGER_BREADCRUMB_DATA",
    data,
  };
}

export function setCurrentPath(data) {
  return {
    type: "SET_CURRENT_PATH",
    data,
  };
}

export function getFileVersions(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getFileVersions`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setFileVersions(response.data.data, data.fileId));
        }
      })
      .catch(() => function () {});
  };
}

export function getFileDependencies(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getFileDependencies`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            setFileDependencies({
              fileVersionId: data.fileVersionId,
              data: response.data.data,
            }),
          );
        }
      })
      .catch(() => function () {});
  };
}

export function saveFileDependencies(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveFileDependencies`,
      data,
    );
  };
}

export function removeFileDependency(data) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/removeFileDependency`,
      data,
    );
  };
}

export function getFileVersionData(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getFileVersionData`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(
            setFilesData({
              [data.fileVersionId]: response.data.data,
            }),
          );
        } else {
          dispatch(
            showToastNotification({
              content: response.data.message,
              type: TOAST_TYPE_ERROR,
            }),
          );
        }
      })
      .catch(() => function () {});
  };
}

export function getResourcesUnderWhichFilesShared(data, returnValue = false) {
  return async (dispatch) => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getResourcesUnderWhichFilesShared`,
      data,
    );
    if (response.data && !returnValue) {
      dispatch(setResourcesUnderWhichFilesShared(response.data, data.fileId));
    }
    if (returnValue) {
      return response.data;
    }
  };
}

export function createFileVersion(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/createFileVersion`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(mergeFileVersions(response.data.data));
        }
      })
      .catch(() => function () {});
  };
}

export function saveNoteInFileVersion(data) {
  return (dispatch) => {
    axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveNoteInFileVersion`,
      data,
    );
  };
}

export function saveFileVersionStatus(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveFileVersionStatus`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setFileVersionsStatus(data));
        }
      })
      .catch(() => function () {});
  };
}

export function saveFileVersionStatusHistory(data) {
  return (dispatch) => {
    let toastType = TOAST_TYPE_ERROR;
    let message = "";
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/signHistory/storeSignHistory`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          toastType = TOAST_TYPE_SUCCESS;
        }
        message = response.data.message;
      })
      .catch(
        () =>
          function () {
            message = "Something went wrong.";
          },
      )
      .finally(() => {
        dispatch(
          showToastNotification({
            content: message,
            type: toastType,
          }),
        );
      });
  };
}

export function saveFileVersionStatusUnderTransmittal(data) {
  return (dispatch) => {
    axiosWrapper
      .post(
        `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveFileVersionStatusUnderTransmittal`,
        data,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(setFileVersionsStatus(data));
        }
      })
      .catch(() => function () {});
  };
}

export const createMergeFile = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/createMergeFile`,
    data,
  );
  return response;
};
export const createFileShortcut = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/createFileShortcut`,
    data,
  );
  return response;
};
export const consumeExternalFiles = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/consumeExternalFiles`,
    data,
  );
  return response;
};
export const getFilesData = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/getFileManagerProps`,
    data,
  );
  return response;
};

export const createMergeEstimate = async (data) => {
  const response = await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/estimate/createMergeEstimate`,
    data,
  );
  return response;
};

export const createContainer = async (data) =>
  await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/createNewContainer`,
    data,
  );

export const saveEngineType = async (data) =>
  await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/saveEngineType`,
    data,
  );

export const createFolder = async (data) =>
  await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/`,
    data,
  );

export function saveCustomFieldsData(data) {
  return axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveCustomFieldsData`,
    data,
  );
}

export function saveCustomMetadata(data) {
  return axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileOperations/saveCustomMetadata`,
    data,
  );
}

export function getFormTemplate(data) {
  return axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/getFormTemplate`,
    data,
  );
}

export function getFormTemplateId(data) {
  return axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/projects/getFormTemplateId`,
    data,
  );
}

export function setCurrentFileVersionId(data) {
  return {
    type: "SET_FILE_VERSION_ID",
    data,
  };
}
export const importExcelContainers = async (data) =>
  await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/fileUpload/importExcelContainers`,
    data,
  );

import { getProjectDashboardPageUrl } from "../../services/utils/helpers";
import { getInspectionUrl } from "@Helpers";
import { getViewerUrl } from "@Helpers";
import { convertDate } from "../../services/luxonHelper";
import _ from "lodash";

export const getNotificationParams = (notification) => {
  let text;
  let proPic;
  let redirectUrl;
  let type;
  const time = convertDate(
    Number(notification.createdDate),
    "getDifferenceTime",
  );
  const id = notification.id;
  const userId = notification.userId;
  let username;
  let userName;
  let createdBy;
  let projectId;

  if (notification.type == "NOTIFICATION_TYPE_TRANSMITTAL_RECEIVED_TO") {
    const transmittalId = `TS-${notification.metadata.NOTIFICATION_DATA_TRANSMITTAL_COUNTER.value}`;
    const spaceName =
      notification.metadata.NOTIFICATION_DATA_FROM_SPACE_NAME.value;

    text = (
      <>
        You received a transmittal <strong>{transmittalId}</strong> from{" "}
        <strong>{spaceName}</strong>
      </>
    );
    redirectUrl = `/project/${notification.metadata.NOTIFICATION_DATA_PROJECT_ID?.value}/listTransmittals/${notification.metadata.NOTIFICATION_DATA_TRANSMITTAL_ID?.value}?projectId=${notification.metadata.NOTIFICATION_DATA_PROJECT_ID?.value}`;
    type = "Transmittal";
  } else if (notification.type == "NOTIFICATION_TYPE_TRANSMITTAL_RECEIVED_CC") {
    const transmittalId = `TS-${notification.metadata.NOTIFICATION_DATA_TRANSMITTAL_COUNTER.value}`;
    const spaceName =
      notification.metadata.NOTIFICATION_DATA_TO_SPACE_NAME.value;
    userName = "";
    if (notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME) {
      userName = notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME.value;
    }
    text = (
      <>
        <strong>{userName}</strong> sent a transmittal{" "}
        <strong>{transmittalId}</strong> to <strong>{spaceName}</strong>
      </>
    );
    redirectUrl = null;
    // redirectUrl = `/project/${notification.metadata.NOTIFICATION_DATA_PROJECT_ID?.value}/listTransmittals/${notification.metadata.NOTIFICATION_DATA_TRANSMITTAL_ID?.value}`;
    type = "Transmittal";
  } else if (notification.type == "NOTIFICATION_TYPE_PROJECT_USER_ADDED") {
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    projectId = notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value;
    text = (
      <>
        You were added to project <strong>{projectName}</strong>
      </>
    );
    redirectUrl = getProjectDashboardPageUrl(projectId);
    type = "Project";
  } else if (notification.type == "NOTIFICATION_TYPE_PROJECT_USER_REMOVED") {
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    projectId = notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value;
    text = (
      <>
        You were removed from project <strong>{projectName}</strong>
      </>
    );

    type = "Project";
  } else if (
    notification.type == "NOTIFICATION_TYPE_PROJECT_USER_ROLE_CHANGED"
  ) {
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    const projectId = notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value;
    const oldRoleName =
      notification.metadata.NOTIFICATION_DATA_FROM_ROLE_NAME?.value;
    const newRoleName =
      notification.metadata.NOTIFICATION_DATA_TO_ROLE_NAME.value;
    text = (
      <>
        Your role was changed from <strong>{oldRoleName}</strong> to
        <strong>{newRoleName}</strong> in <strong>{projectName}</strong>
      </>
    );
    redirectUrl = getProjectDashboardPageUrl(projectId);
    type = "Setting";
  } else if (notification.type == "NOTIFICATION_TYPE_SPACE_USER_ADDED") {
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    const spaceName = notification.metadata.NOTIFICATION_DATA_SPACE_NAME.value;
    text = (
      <>
        You were added to space <strong>{spaceName}</strong> in{" "}
        <strong>{projectName}</strong>
      </>
    );

    type = "Space";
  } else if (notification.type == "NOTIFICATION_TYPE_COMMENT_ADDED") {
    let comment =
      notification.metadata.NOTIFICATION_DATA_COMMENT_CONTENT.value.substring(
        0,
        100,
      );
    type = "Issue";
    comment = comment.replace(/<[^>]+>/g, "");
    username = notification.metadata.NOTIFICATION_DATA_COMMENT_ADDED_BY.value;
    const issueId = notification.metadata.NOTIFICATION_DATA_ISSUE_ID.value;
    const FileId = notification.metadata.NOTIFICATION_DATA_FILE_ID?.value;
    const projectId = notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value;
    const issueCounter =
      notification.metadata.NOTIFICATION_DATA_ISSUE_COUNTER.value;
    const spaceId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_SPACE_ID.value",
    );
    let awsFileName =
      notification.metadata.NOTIFICATION_DATA_AWS_FILE_NAME?.value;
    awsFileName = awsFileName?.substring(0, awsFileName.lastIndexOf("."));
    text = (
      <>
        <strong>{username}</strong> commented on issue{" "}
        <strong>
          {projectId}
          -IS000
          {issueCounter}:
        </strong>{" "}
        {comment}
      </>
    );

    const url = getViewerUrl({
      fileVersionId: FileId,
      issueId,
      spaceId,
      projectId,
    });
    redirectUrl = FileId && spaceId ? url : "";
  } else if (notification.type == "NOTIFICATION_TYPE_ISSUE_CREATED") {
    // let title = notification.metadata.NOTIFICATION_DATA_ISSUE_NAME.value;
    const ProjectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    username = notification.metadata.NOTIFICATION_DATA_ISSUE_CREATED_BY.value;
    const issueId = notification.metadata.NOTIFICATION_DATA_ISSUE_ID.value;
    const FileId = notification.metadata.NOTIFICATION_DATA_FILE_ID?.value;
    const projectId = notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value;
    const issueCounter =
      notification.metadata.NOTIFICATION_DATA_ISSUE_COUNTER.value;
    const spaceId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_SPACE_ID.value",
    );
    let awsFileName =
      notification.metadata.NOTIFICATION_DATA_AWS_FILE_NAME?.value;
    type = "Issue";
    awsFileName = awsFileName?.substring(0, awsFileName.lastIndexOf("."));
    text = (
      <>
        <strong>{username}</strong> assigned an issue{" "}
        <strong>
          {projectId}
          -IS000
          {issueCounter}{" "}
        </strong>
        to you in <strong>{ProjectName}</strong>
      </>
    );

    const url = getViewerUrl({
      fileVersionId: FileId,
      issueId,
      spaceId,
      projectId,
    });
    redirectUrl = FileId && spaceId ? url : "";
  } else if (
    notification.type == "NOTIFICATION_TYPE_FILE_CONVERSION_COMPLETED"
  ) {
    const fileName = notification.metadata.NOTIFICATION_DATA_FILE_NAME.value;
    const fileVersionId =
      notification.metadata.NOTIFICATION_DATA_FILE_VERSION_ID.value;
    const spaceId = notification.metadata.NOTIFICATION_DATA_SPACE_ID.value;
    const projectId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_PROJECT_ID.value",
    );
    type = "File";
    const url = getViewerUrl({
      fileVersionId,
      spaceId,
      projectId,
    });
    text = (
      <>
        File <strong>{fileName}</strong> is converted successfully
      </>
    );

    redirectUrl = url;
  } else if (notification.type == "NOTIFICATION_TYPE_TASK_USER_ADDED") {
    userName = "";
    if (notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME) {
      userName = notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME.value;
    }
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    const spaceName = notification.metadata.NOTIFICATION_DATA_SPACE_NAME.value;
    const taskName = `TSK-${notification.metadata.NOTIFICATION_DATA_TASK_NAME.value}`;
    text = (
      <>
        <strong>{userName}</strong> assigned a task <strong>{taskName}</strong>{" "}
        to you in <strong>{projectName}</strong> within{" "}
        <strong>{spaceName}</strong>
      </>
    );

    redirectUrl = `/project/${notification.metadata.NOTIFICATION_DATA_PROJECT_ID?.value}/listTask/${notification.metadata.NOTIFICATION_DATA_TASK_VERSION_ID?.value}`;
    type = "Task";
  } else if (notification.type == "NOTIFICATION_TYPE_ESTIMATE_STATUS_UPDATED") {
    userName =
      notification.metadata?.NOTIFICATION_DATA_STATUS_CHANGED_BY?.value;
    const projectName =
      notification.metadata?.NOTIFICATION_DATA_PROJECT_NAME?.value;
    const spaceName =
      notification.metadata?.NOTIFICATION_DATA_SPACE_NAME?.value;
    const estimateName =
      notification.metadata?.NOTIFICATION_DATA_ESTIMATE_NAME?.value;
    const prevStatus =
      notification.metadata?.NOTIFICATION_DATA_ESTIMATE_PREVIOUS_STATUS?.value;
    const currentStatus =
      notification.metadata?.NOTIFICATION_DATA_ESTIMATE_CURRENT_STATUS?.value;
    type = "Estimate";
    text = (
      <>
        <strong>{userName}</strong> changed the status of Estimate{" "}
        <strong>{estimateName}</strong> from <strong>{prevStatus}</strong> to{" "}
        <strong>{currentStatus}</strong> in Project{" "}
        <strong>{projectName}</strong> within Space <strong>{spaceName}</strong>
      </>
    );
  } else if (notification.type == "NOTIFICATION_TYPE_TASK_STATUS_CHANGED") {
    userName = "";
    if (notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME) {
      userName = notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME.value;
    }
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    const spaceName = notification.metadata.NOTIFICATION_DATA_SPACE_NAME.value;
    const taskName = `TSK-${notification.metadata.NOTIFICATION_DATA_TASK_NAME.value}`;
    let taskStatusName =
      notification.metadata.NOTIFICATION_DATA_TASK_STATUS_NAME.value;
    taskStatusName =
      taskStatusName.charAt(0).toUpperCase() +
      taskStatusName.substring(1).toLocaleLowerCase().replace(/_/g, " ");
    text = (
      <>
        <strong>{userName}</strong> changed the status of task{" "}
        <strong>{taskName}</strong> to
        <strong>{taskStatusName}</strong> in <strong>{projectName}</strong>{" "}
        within <strong>{spaceName}</strong>
      </>
    );
    redirectUrl = `/project/${notification.metadata.NOTIFICATION_DATA_PROJECT_ID?.value}/listTask/${notification.metadata.NOTIFICATION_DATA_TASK_VERSION_ID?.value}`;
    type = "Task";
  } else if (
    notification.type ==
    "NOTIFICATION_TYPE_FILE_STATUS_CHANGED_UNDER_TRANSMITTAL"
  ) {
    type = "File";
    userName = "";
    if (notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME) {
      userName = notification.metadata.NOTIFICATION_DATA_FROM_USER_NAME.value;
    }
    const fileName =
      notification.metadata.NOTIFICATION_DATA_FILE_NAME?.value || "";
    let fromStatus = notification.metadata.NOTIFICATION_DATA_FROM_STATUS.value;
    let toStatus = notification.metadata.NOTIFICATION_DATA_TO_STATUS.value;
    const projectName =
      notification.metadata.NOTIFICATION_DATA_PROJECT_NAME.value;
    const projectId =
      notification.metadata.NOTIFICATION_DATA_PROJECT_PROJECT_ID?.value;
    const spaceName = notification.metadata.NOTIFICATION_DATA_SPACE_NAME.value;
    let note = "";
    if (notification.metadata.NOTIFICATION_DATA_NOTE) {
      note = notification.metadata.NOTIFICATION_DATA_NOTE.value;
    }

    fromStatus =
      fromStatus.charAt(0).toUpperCase() +
      fromStatus.substring(1).toLocaleLowerCase().replace(/_/g, " ");
    toStatus =
      toStatus.charAt(0).toUpperCase() +
      toStatus.substring(1).toLocaleLowerCase().replace(/_/g, " ");
    text = (
      <>
        <strong>{userName}</strong> changed the status of file{" "}
        <strong>{fileName}</strong> from
        <strong>{fromStatus}</strong> to <strong>{toStatus}</strong> in
        <strong>{projectId}</strong> -<strong>{projectName}</strong> within{" "}
        {spaceName}
      </>
    );

    if (note) {
      text = (
        <>
          {text} with note
          <strong>{note}</strong>
        </>
      );
    }
    type = "File";
  } else if (notification.type == "NOTIFICATION_TYPE_REVIEW_PENDING") {
    type = "Review";
    redirectUrl = `/review?reviewId=${notification.metadata.NOTIFICATION_DATA_REVIEW_ID.value}&spaceId=${notification.metadata.NOTIFICATION_DATA_SPACE_ID.value}&projectId=${notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value}`;
    text = (
      <>
        Action pending for review in{" "}
        <strong>
          {notification.metadata.NOTIFICATION_DATA_REVIEW_NAME.value}
        </strong>{" "}
        {notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE?.value &&
          `with comment : ${notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE.value}`}
      </>
    );
  } else if (notification.type == "NOTIFICATION_TYPE_ROLLBACK_REVIEW_PENDING") {
    type = "Review";
    redirectUrl = `/review?reviewId=${notification.metadata.NOTIFICATION_DATA_REVIEW_ID.value}&spaceId=${notification.metadata.NOTIFICATION_DATA_SPACE_ID.value}&projectId=${notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value}`;
    text = (
      <>
        Action pending for a rollbacked review in{" "}
        <strong>
          {notification.metadata.NOTIFICATION_DATA_REVIEW_NAME.value}
        </strong>{" "}
        {notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE?.value &&
          `with comment : ${notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE.value}`}
      </>
    );
  } else if (notification.type == "NOTIFICATION_TYPE_REVIEW_CREATED") {
    type = "Review";
    redirectUrl = `/review?reviewId=${notification.metadata.NOTIFICATION_DATA_REVIEW_ID.value}&spaceId=${notification.metadata.NOTIFICATION_DATA_SPACE_ID.value}&projectId=${notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value}`;

    text = (
      <>
        A review{" "}
        <strong>
          {notification.metadata.NOTIFICATION_DATA_REVIEW_NAME.value}
        </strong>{" "}
        has been created :
        {notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE?.value}
      </>
    );
  } else if (notification.type == "NOTIFICATION_TYPE_REVIEW_REVOKED") {
    type = "Review";
    redirectUrl = `/review?reviewId=${notification.metadata.NOTIFICATION_DATA_REVIEW_ID.value}&spaceId=${notification.metadata.NOTIFICATION_DATA_SPACE_ID.value}&projectId=${notification.metadata.NOTIFICATION_DATA_PROJECT_ID.value}`;
    text = (
      <>
        A review{" "}
        <strong>
          {notification.metadata.NOTIFICATION_DATA_REVIEW_NAME.value}
        </strong>{" "}
        has been revoked :{" "}
        {notification.metadata.NOTIFICATION_DATA_REVIEW_MESSAGE?.value}
      </>
    );
  } else if (notification.type == "NOTIFICATION_DATA_FORM_CREATED") {
    type = "GeneralForms";
    createdBy = notification.metadata.NOTIFICATION_DATA_FORM_CREATEDBY.value;
    const title = notification.metadata.NOTIFICATION_DATA_FORM_TITLE.value;
    const projectId =
      notification.metadata.NOTIFICATION_DATA_FORM_PROJECTID.value;
    const resourceId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_RESOURCEID.value",
    );
    const contractId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_CONTRACTID.value",
    );
    const task = notification.metadata.NOTIFICATION_DATA_FORM_TASK?.value;

    text =
      task == "formCreated" ? (
        <>
          <strong>{createdBy}</strong> created a form <strong>{title} </strong>
          and you are part of it.
        </>
      ) : task == "levelSubmit" ? (
        <>
          Form <strong>{title}</strong> which you are part of has been proceeded
          to next level
        </>
      ) : (
        <>
          Form <strong>{title}</strong> which you are part of has been proceeded
          to final level
        </>
      );
    redirectUrl = resourceId
      ? getInspectionUrl({
          projectId,
          resourceId,
          contractId,
        })
      : null;
  } else if (notification.type == "NOTIFICATION_DATA_FILE_UPLOADED") {
    type = "File";
    const files =
      notification.metadata.NOTIFICATION_DATA_FILE_ESCROW_BATCH_FILES.value;
    const batchId =
      notification.metadata.NOTIFICATION_DATA_FILE_ESCROW_BATCH_ID.value;
    text = (
      <>
        {"There is a batch of "}
        <strong>{`${files} ${files == 1 ? "file" : "files"}`}</strong>
        {" that requires your action to "}
        <strong>finalise your upload.</strong>
      </>
    );
    redirectUrl = `escrowFiles/${batchId}`;
  } else if (
    notification.type == "NOTIFICATION_DATA_FORM_DUEDATE_NOTIFICATION"
  ) {
    type = "GeneralForms";
    createdBy = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_PROJECTID.value",
    );
    const formTitle = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_TITLE.value",
    );
    const formNo = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_NO.value",
    );
    const formDueDate = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_DUEDATE.value",
    );
    const formType = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_RESOURCE_TYPE.value",
    );
    const projectId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_PROJECTID.value",
    );
    const resourceId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_RESOURCEID.value",
    );
    const contractId = _.get(
      notification,
      "metadata.NOTIFICATION_DATA_FORM_CONTRACTID.value",
    );
    redirectUrl = getInspectionUrl({ projectId, resourceId, contractId });

    text = (
      <>
        {"Form "}
        <strong>{formNo}</strong>-<strong>{formTitle}</strong>
        {" will expire on "}
        <strong>
          {convertDate(Number(formDueDate), "dateForToolTipModal")}
        </strong>
      </>
    );
  }

  return {
    proPic,
    text,
    time,
    id,
    redirectUrl,
    createdDate: notification.createdDate,
    type,
    read: notification.read,
    userId,
    username,
    userName,
    createdBy,
    projectId,
  };
};

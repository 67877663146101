import { lazy } from "react";
import Welcome from "./pages/Welcome";
import SubscriptionWarning from "./pages/SubscriptionWarning";

const DesignViewer = lazy(
  () => import("./components/DesignViewer/DesignViewer"),
);
const Issues = lazy(() => import("./components/Issues/Issues"));
const FileExplorer = lazy(() => import("./pages/FileExplorer"));
const MobileExplorer = lazy(() => import("./pages/FileExplorer/Mobile.jsx"));
const TaskDetails = lazy(
  () => import("./pages/TaskManagement/TaskDetails/TaskDetails"),
);
const TransmitalsList = lazy(
  () => import("./pages/Transmitals/ListTransmittals/listTransmittals"),
);
const TransmitalDetails = lazy(
  () => import("./pages/Transmitals/TransmitalDetails/TransmitalDetails"),
);
const TaskList = lazy(() => import("./pages/TaskManagement/ListTask/listTask"));
const Gantt = lazy(() => import("./components/Schedule/Gantt"));
const PortalDashboard = lazy(() => import("./components/PortalDashboard"));
const ProjectDashboard = lazy(() => import("./components/ProjectDashboard"));
const Transmitals = lazy(() => import("./pages/Transmitals/transmitals"));
const PortalSetting = lazy(
  () => import("./pages/PortalSettings/PortalSettings"),
);
const UserProfile = lazy(() => import("./pages/UserProfile"));
const ProjectSettings = lazy(() => import("./pages/ProjectSettings"));
const TaskCreation = lazy(() => import("./pages/TaskManagement/TaskCreation"));
const NotificationsPage = lazy(
  () => import("./pages/Notifications/NotificationPage"),
);
const EscrowFiles = lazy(() => import("./pages/Notifications/EscrowFiles"));
const Workspaces = lazy(() => import("./pages/Workspaces"));
const ProjectsPage = lazy(() => import("./pages/Projects/ProjectsPage"));
const Search = lazy(() => import("./components/Search/Search"));
const LoginCallback = lazy(
  () => import("./components/LoginCallback/LoginCallback"),
);
const SignInPage = lazy(() => import("./pages/SignIn"));
const CompanySettings = lazy(
  () => import("./pages/CompanySettings/CompanySettings"),
);
const SpaceSettings = lazy(() => import("./pages/SpaceSettings/SpaceSettings"));
const EstimateListPage = lazy(() => import("./pages/EstimateList"));
const Estimate = lazy(() => import("./pages/Estimation/Estimate"));
const Diagram = lazy(() => import("./components/Diagram"));
const Workflow = lazy(() => import("./components/Workflow"));
const SuperAdmin = lazy(() => import("./pages/SuperAdmin"));
const Forbidden = lazy(() => import("./pages/Error/Forbidden"));
const Risk = lazy(() => import("./pages/RiskManagement"));
const AssetRegister = lazy(() => import("./components/AssetRegister"));
const AssetList = lazy(() => import("./components/AssetList"));
const ChangeOrder = lazy(() => import("./components/ChangeOrder"));
const ScheduleListPage = lazy(
  () => import("./pages/Schedules/ScheduleListPage"),
);
const GeneralFormsPage = lazy(() => import("./pages/Forms/generalForms"));
const ContractsPage = lazy(() => import("./pages/Contracts"));
const ContractDetails = lazy(
  () => import("./pages/Contracts/ContractDetails.jsx"),
);
const InspectionListPage = lazy(() => import("./pages/Forms/inspection"));
const ProgressUpdatesFormPage = lazy(
  () => import("./pages/Forms/progressUpdates"),
);
const ChangeOrderFormPage = lazy(() => import("./pages/Forms/changeOrder"));
const BillFormPage = lazy(() => import("./pages/Forms/bills"));
const PermitToWorkFormPage = lazy(() => import("./pages/Forms/permitToWork"));
const PaymentsFormPage = lazy(() => import("./pages/Forms/payments"));
const ResourceTypeList = lazy(
  () => import("./components/Resource/ResourceType"),
);
const ResourceList = lazy(() => import("./components/Resource"));
const GoogleSigninButton = lazy(
  () => import("./components/GoogleSigninButton"),
);
const ThankYou = lazy(() => import("./components/ThankYou"));
const ScheduleDetails = lazy(
  () => import("./components/Schedule/ScheduleDetails"),
);
const CreateProject = lazy(
  () => import("./components/ProjectCreation/CreateProjectModal"),
);
const ClashTests = lazy(() => import("./pages/ClashTests"));
const ClashTestDetails = lazy(() => import("./components/ClashTests/Details"));
const FututreModuleIllustration = lazy(
  () =>
    import("./components/FututreModuleIllustration/FututreModuleIllustration"),
);
const ProjectFiles = lazy(() => import("./pages/Files/index2"));
const PortalFiles = lazy(() => import("./pages/Files/PortalFiles"));
const CreateInspectionAndProgres = lazy(
  () => import("./pages/CreateInspectionAndProgress"),
);
const EstimatePortalList = lazy(
  () => import("./pages/Estimation/EstimateList"),
);
const ReviewPage = lazy(() => import("./pages/Reviews/Review"));
const Mailbox = lazy(() => import("./pages/Mailbox/index"));
const Reviews = lazy(() => import("./pages/Reviews/index.jsx"));
const MailThread = lazy(() => import("./pages/Mailbox/MailThread"));
const PdfGenerator = lazy(
  () => import("./components/PdfGenerator/PdfGenerator"),
);
const isSuperAdminUrl = window.location.host.startsWith("config.");

const routes = [
  {
    path: ["/", "/resetPassword"],
    element: <SignInPage />,
    exact: true,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/googleSigninButton"],
    element: <GoogleSigninButton />,
    exact: true,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/thank-you"],
    element: <ThankYou />,
    exact: true,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/signup", "/95789"],
    element: <SignInPage formType="signup" />,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/invitation"],
    element: <SignInPage formType="invitation" />,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/user-profile/:settingType"],
    element: <UserProfile />,
    showStickySidebar: false,
  },
  {
    path: ["/dashboard"],
    element: isSuperAdminUrl ? <SuperAdmin /> : <PortalDashboard />,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/welcome"],
    element: <Welcome />,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/subscriptionWarning"],
    element: <SubscriptionWarning />,
    showStickySidebar: false,
    showHeader: false,
  },
  {
    path: ["/googleLoginCallback"],
    element: <LoginCallback />,
    showStickySidebar: false,
  },
  {
    path: ["/projects"],
    element: <ProjectsPage />,
    showStickySidebar: false,
  },
  { path: ["/403"], element: <Forbidden />, showStickySidebar: false },
  { path: ["/project/:projectId/mails"], element: <Mailbox /> },
  { path: ["/reviews"], element: <Reviews /> },
  {
    path: ["/project/:projectId/mails/:mailId/mailThread"],
    element: <MailThread />,
  },
  { path: ["/workspace/:workspaceId/explorer"], element: <FileExplorer /> },
  {
    path: ["/workspace/:workspaceId/mobileExplorer"],
    element: <MobileExplorer />,
    showStickySidebar: false,
    showHeader: false,
  },
  { path: ["/Files"], element: <PortalFiles /> },
  {
    path: ["/workspace/:workspaceId/file/:fileid/issues"],
    element: <Issues />,
  },
  { path: ["/project/:projectId/issues"], element: <Issues /> },
  { path: ["/issuesList"], element: <Issues /> },
  { path: ["/project/:projectId/setting"], element: <ProjectSettings /> },
  {
    path: ["/spaceSettings/:workspaceId/:settingType?"],
    element: <SpaceSettings />,
  },
  {
    path: ["/project/:projectId/createTransmittals"],
    element: <Transmitals />,
  },
  {
    path: ["/project/:projectId/listTransmittals"],
    element: <TransmitalsList />,
    exact: true,
  },
  {
    path: ["/project/:projectId/listFiles"],
    element: <ProjectFiles />,
    exact: true,
  },
  {
    path: ["/project/:projectId/EstimateList"],
    element: <EstimateListPage />,
  },
  {
    path: ["/project/:projectId/listTransmittals/:transmittalVersionId"],
    element: <TransmitalDetails />,
  },
  {
    path: ["/project/:projectId/estimate/:estimateId"],
    element: <Estimate />,
    exact: true,
  },
  {
    path: ["/project/:projectId/estimate/:estimateId/version/:versionId"],
    element: <Estimate />,
    exact: true,
  },
  { path: ["/transmittals"], element: <TransmitalsList /> },
  { path: ["/project/:projectId/createTasks"], element: <TaskCreation /> },
  {
    path: ["/project/:projectId/editTask/:taskId"],
    element: <TaskCreation />,
  },
  {
    path: ["/project/:projectId/editTask/:taskId/schedule/:scheduleId"],
    element: <TaskCreation />,
  },
  {
    path: ["/project/:projectId/schedule/:scheduleId/createTasks"],
    element: <TaskCreation />,
  },
  {
    path: [
      "/project/:projectId/schedule/:scheduleId/spaceId/:spaceId/createTasks",
    ],
    element: <TaskCreation />,
  },
  {
    path: ["/project/:projectId/listTask"],
    element: <TaskList />,
    exact: true,
  },
  {
    path: ["/project/:projectId/forms/:resourceId"],
    element: <CreateInspectionAndProgres />,
    exact: true,
  },
  {
    path: ["/project/:projectId/listTask/:taskId"],
    element: <TaskDetails />,
  },
  { path: ["/tasks"], element: <TaskList /> },
  {
    path: ["/portalSettings/:settingType"],
    element: <PortalSetting />,
    exact: true,
    showStickySidebar: false,
  },
  {
    path: ["/portalSettings/:settingType/:formId/:formCategory"],
    element: <PortalSetting />,
    exact: true,
    showStickySidebar: false,
  },
  {
    path: ["/portalSettings/:settingType/:dsrId"],
    element: <PortalSetting />,
    exact: true,
    showStickySidebar: false,
  },
  {
    path: ["/portalSettings/:settingType/:dsrId/dsrVersion/:dsrVersionId"],
    element: <PortalSetting />,
    exact: true,
    showStickySidebar: false,
  },
  {
    path: ["/companySettings/:companyId/:settingType?"],
    element: <CompanySettings />,
    showStickySidebar: false,
  },
  {
    path: ["/project/:projectId/projectdashboard"],
    element: <ProjectDashboard />,
  },
  { path: ["/project/:projectId/Workspaces"], element: <Workspaces /> },
  { path: ["/search"], element: <Search /> },
  {
    path: ["/project/:projectId/contractsList"],
    element: <ContractsPage />,
    exact: true,
  },
  {
    path: ["/contractsList"],
    element: <ContractsPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/contract/:contractId"],
    element: <ContractDetails />,
    exact: true,
  },
  {
    path: ["/project/:projectId/generalFormsList"],
    element: <GeneralFormsPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/inspectionFormsList"],
    element: <InspectionListPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/progressUpdatesFormsList"],
    element: <ProgressUpdatesFormPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/changeOrderFormsList"],
    element: <ChangeOrderFormPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/billsFormsList"],
    element: <BillFormPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/paymentsFormsList"],
    element: <PaymentsFormPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/permitToWorkFormsList"],
    element: <PermitToWorkFormPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/space/:spaceId/gantt"],
    element: <Gantt />,
    exact: true,
  },
  {
    path: ["/project/:projectId/scheduleList"],
    element: <ScheduleListPage />,
    exact: true,
  },
  {
    path: ["/project/:projectId/schedule/:scheduleId/ScheduleDetails"],
    element: <ScheduleDetails />,
    exact: true,
  },
  {
    path: ["/project/:projectId/resourceTypeList"],
    element: <ResourceTypeList />,
    exact: true,
  },
  {
    path: ["/project/:projectId/resourceList"],
    element: <ResourceList />,
    exact: true,
  },
  {
    path: ["/project/:projectId/changeOrder"],
    element: <FututreModuleIllustration moduleName="Change Order" />,
    exact: true,
  },
  {
    path: ["/project/:projectId/assetRegister/:assetRegisterId"],
    element: <AssetList />,
    exact: true,
  },
  {
    path: ["/project/:projectId/workflow/:workflowId/diagram"],
    element: <Diagram />,
  },
  {
    path: ["/project/:projectId/workflow"],
    element: <FututreModuleIllustration moduleName="Workflow" />,
    exact: true,
  },
  {
    path: ["/designviewer/file/:fileId"],
    element: <DesignViewer />,
    showHeader: false,
    showStickySidebar: false,
  },
  {
    path: ["/project/:projectId/clashTests"],
    element: <ClashTests />,
    exact: true,
  },
  {
    path: ["/project/:projectId/clashDetails/:clashDetectionId"],
    element: <ClashTestDetails />,
  },
  {
    path: ["/project/:projectId/riskManagement"],
    element: <FututreModuleIllustration moduleName="Risks" />,
  },
  {
    path: ["/project/:projectId/tendersList"],
    element: <FututreModuleIllustration moduleName="Tenders" />,
  },
  {
    path: ["/project/:projectId/assetRegister"],
    element: <FututreModuleIllustration moduleName="Assets" />,
  },
  {
    path: ["/project/:projectId/procurement"],
    element: <FututreModuleIllustration moduleName="Procurement" />,
  },
  {
    path: ["/notifications"],
    element: <NotificationsPage />,
    showStickySidebar: false,
  },
  {
    path: ["/escrowFiles/:batchId"],
    element: <EscrowFiles />,
    showStickySidebar: false,
  },
  {
    path: ["/project/createNewProject"],
    element: <CreateProject />,
    exact: true,
  },
  {
    path: ["/estimatePortalList"],
    element: <EstimatePortalList />,
    exact: true,
    showStickySidebar: false,
  },
  { path: ["/review"], element: <ReviewPage />, exact: true },
  { path: ["/reviews?byPortal=true"], element: <ReviewPage />, exact: true },
  {
    path: [":module/pdfExport"],
    element: <PdfGenerator />,
    showStickySidebar: false,
    showHeader: false,
  },
];

export default routes;

import _ from "lodash";
import { FieldType, Formatters } from "slickgrid-react";

export const getFilesGridColumns = ({
  gridNameTemplate,
  batchId,
  isFileVersionUploading,
  isFolderUpload,
  gridNomenclatureTemplate,
  fileUploadList,
  template,
  gridCustomFieldTemplate,
  gridUploadActionTemplate,
  fileNomenclature,
  handleNomenclatureChange,
  progressTemplate,
  progressPercentageTemplate,
  columnHeaderTeplate,
}) => {
  const filesGridColumns = [
    {
      field: "name",
      id: "name",
      width: 240,
      originalWidth: 240,
      minWidth: 150,
      // type: FieldType.string,
      // minWidth: 10,
      textAlign: "Left",
      formatter: gridNameTemplate,
      sortable: true,
      headerText: "Name",
      name: "Name",
    },
  ];
  if (!batchId) {
    filesGridColumns.push(
      ...[
        {
          field: "progress",
          id: "progressBar",
          width: 120,
          originalWidth: 120,
          padding: 4,
          // minWidth: 10,
          type: FieldType.number,
          textAlign: "Left",
          formatter: (_row, _cell, _value, _col, args) =>
            progressTemplate(_row, _cell, _value, _col, args),
          // filter: {
          //   filterPredicate: (columnData, searchData) => fileNameTemplate(columnData).toLowerCase().includes(searchData.parsedSearchTerms[0].toLowerCase()),
          // },
          sortable: false,
          headerText: "Progress",
          name: "Progress",
        },
        {
          field: "progress",
          id: "progress",
          width: 50,
          originalWidth: 50,
          // type: FieldType.string,
          // minWidth: 10,
          type: "string",
          textAlign: "Left",
          // formatter: Formatters.progressBar,
          formatter: (_row, _cell, _value, _col, args) =>
            progressPercentageTemplate(_row, _cell, _value, _col, args),
          // formatter: (data, params, a, b, value) => fileNameTemplate(value),
          // filter: {
          //   filterPredicate: (columnData, searchData) => fileNameTemplate(columnData).toLowerCase().includes(searchData.parsedSearchTerms[0].toLowerCase()),
          // },
          sortable: false,
          headerText: "",
          name: "",
        },
      ],
    );
    if (!isFileVersionUploading && !isFolderUpload) {
      filesGridColumns.push({
        field: "nomenclature",
        id: "nomenclature",
        originalWidth: 200,
        minWidth: 180,
        visible: !isFolderUpload,
        // type: FieldType.string,
        // minWidth: 10,
        type: "string",
        textAlign: "Left",
        formatter: (_row, _cell, _value, _col, args) =>
          gridNomenclatureTemplate(
            _row,
            _cell,
            _value,
            _col,
            args,
            fileNomenclature,
            handleNomenclatureChange,
          ),
        // filter: {
        //   filterPredicate: (columnData, searchData) => fileNameTemplate(columnData).toLowerCase().includes(searchData.parsedSearchTerms[0].toLowerCase()),
        // },
        sortable: false,
        headerText: "Nomenclature",
        name: "Nomenclature",
      });
    }
  } else if (
    fileUploadList.filter((file) => !file.isFileNomenclatureCorrect).length
  ) {
    filesGridColumns.push({
      field: "nomenclature",
      id: "nomenclature",
      originalWidth: 200,
      minWidth: 180,
      // type: FieldType.string,
      // minWidth: 10,
      type: "string",
      textAlign: "Left",
      formatter: (_row, _cell, _value, _col, args) =>
        gridNomenclatureTemplate(
          _row,
          _cell,
          _value,
          _col,
          args,
          fileNomenclature,
          handleNomenclatureChange,
        ),
      // filter: {
      //   filterPredicate: (columnData, searchData) => fileNameTemplate(columnData).toLowerCase().includes(searchData.parsedSearchTerms[0].toLowerCase()),
      // },
      sortable: false,
      headerText: "Nomenclature",
      name: "Nomenclature",
    });
  }

  const gridCustomFieldsColumns = [];
  if (!isFileVersionUploading) {
    template?.forEach((item) => {
      item.questionFields.forEach((field) => {
        gridCustomFieldsColumns.push({
          field: field._id,
          id: field._id,
          "data-field": field,
          width: 160,
          originalWidth: 160,
          // type: FieldType.string,
          // minWidth: 10,
          // textAlign: 'Left',
          // freeze: 'Left',
          formatter: gridCustomFieldTemplate,
          headerTemplate: columnHeaderTeplate,
          // filter: {
          //   filterPredicate: (columnData, searchData) => fileNameTemplate(columnData).toLowerCase().includes(searchData.parsedSearchTerms[0].toLowerCase()),
          // },
          sortable: false,
          headerText: field.questionValue,
          name: field.questionValue,
        });
      });
    });

    filesGridColumns.push(...gridCustomFieldsColumns);

    const uploadColumn = {
      id: "uploadAction",
      headerText: "Action Required",
      freeze: "Right",
      originalWidth: 360,
      minWidth: 240,
      formatter: gridUploadActionTemplate,
    };

    if (
      batchId ||
      !!fileUploadList.filter(
        (file) => file.isDuplicate && !file.isDeleted && !file.isFolder,
      ).length
    ) {
      filesGridColumns.push(uploadColumn);
    }
  }
  return filesGridColumns;
};

import React from "react";
import ChangeColor from "../../HoopsViewer/ToolbarViewer/ChangeColor";

export function PlusIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 2.75a.75.75 0 0 0-1.5 0V7H2.75a.75.75 0 0 0 0 1.5H7v4.25a.75.75 0 1 0 1.5 0V8.5h4.25a.75.75 0 1 0 0-1.5H8.5V2.75z"
        fill={color}
      />
    </svg>
  );
}
export function EditIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.667 13.333H3.333a.667.667 0 0 0 0 1.334h9.334a.667.667 0 0 0 0-1.334zM3.333 12h.06l2.78-.253c.305-.03.59-.165.807-.38l6-6a1.28 1.28 0 0 0-.047-1.807l-1.826-1.827a1.333 1.333 0 0 0-1.774-.046l-6 6c-.215.217-.35.502-.38.806l-.286 2.78a.667.667 0 0 0 .666.727zm6.847-9.333L12 4.487l-1.333 1.3L8.88 4l1.3-1.333zm-5.933 5.94L8 4.88l1.8 1.8-3.733 3.733-2 .187.18-1.993z"
        fill={color}
      />
    </svg>
  );
}

export function DeleteIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.667 2.665A1.333 1.333 0 0 1 6 1.332h4a1.333 1.333 0 0 1 1.333 1.333V4H14a.667.667 0 1 1 0 1.333h-.713l-.578 8.095a1.333 1.333 0 0 1-1.33 1.238H4.62a1.333 1.333 0 0 1-1.33-1.238l-.576-8.095H2a.667.667 0 0 1 0-1.333h2.667V2.665zM6 4h4V2.665H6V4zM4.05 5.332l.57 8h6.76l.571-8H4.05zm2.617 1.333a.667.667 0 0 1 .666.667v4a.667.667 0 1 1-1.333 0v-4a.667.667 0 0 1 .667-.667zm2.666 0a.667.667 0 0 1 .667.667v4a.667.667 0 1 1-1.333 0v-4a.667.667 0 0 1 .666-.667z"
        fill={color}
      />
    </svg>
  );
}

export function DeactivateIcon({ color }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00016 0.333008C10.6668 0.333008 13.6668 3.33301 13.6668 6.99967C13.6668 10.6663 10.6668 13.6663 7.00016 13.6663C3.3335 13.6663 0.333496 10.6663 0.333496 6.99967C0.333496 3.33301 3.3335 0.333008 7.00016 0.333008ZM7.00016 1.66634C5.7335 1.66634 4.60016 2.06634 3.7335 2.79967L11.2002 10.2663C11.8668 9.33301 12.3335 8.19967 12.3335 6.99967C12.3335 4.06634 9.9335 1.66634 7.00016 1.66634ZM10.2668 11.1997L2.80016 3.73301C2.06683 4.59967 1.66683 5.73301 1.66683 6.99967C1.66683 9.93301 4.06683 12.333 7.00016 12.333C8.26683 12.333 9.40016 11.933 10.2668 11.1997Z"
        fill="#192048"
      />
    </svg>
  );
}

export function PartialVisibleIcon({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Partially visible">
        <g id="Vector">
          <mask
            id="path-1-outside-1_3989_20228"
            maskUnits="userSpaceOnUse"
            x="0.0234375"
            y="3.21875"
            width="24"
            height="17"
            fill="black"
          >
            <rect
              fill="white"
              x="0.0234375"
              y="3.21875"
              width="24"
              height="17"
            />
            <path d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96503 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1057 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5193 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5193 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1057 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z" />
            <path d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3929 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66144 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z" />
            <path d="M22.9766 13.0238C21.4789 11.1972 19.6713 9.64853 17.6366 8.44875L18.5366 7.545C18.5799 7.50165 18.6143 7.4502 18.6377 7.39361C18.6612 7.33699 18.6733 7.27627 18.6733 7.215C18.6733 7.15372 18.6612 7.09301 18.6377 7.03639C18.6143 6.9798 18.5799 6.92835 18.5366 6.885C18.4932 6.84165 18.4418 6.8073 18.3852 6.78383C18.3285 6.76039 18.2679 6.74831 18.2066 6.74831C18.1453 6.74831 18.0846 6.76039 18.028 6.78383C17.9713 6.8073 17.9199 6.84165 17.8766 6.885L16.7516 7.99125C15.4439 7.34186 14.0199 6.9594 12.5628 6.86625V4.6875C12.5628 4.56319 12.5134 4.44394 12.4255 4.35604C12.3376 4.26814 12.2184 4.21875 12.0941 4.21875C11.9697 4.21875 11.8505 4.26814 11.7626 4.35604C11.6747 4.44394 11.6253 4.56319 11.6253 4.6875V6.85875C10.1682 6.9519 8.74428 7.33436 7.43658 7.98375L6.31158 6.8775C6.22405 6.78998 6.10533 6.74081 5.98158 6.74081C5.85779 6.74081 5.7391 6.78998 5.65158 6.8775C5.56405 6.96503 5.51489 7.08371 5.51489 7.2075C5.51489 7.33129 5.56405 7.44998 5.65158 7.5375L6.55158 8.44125C4.52159 9.6447 2.71912 11.1958 1.22656 13.0238C1.16083 13.1057 1.125 13.2075 1.125 13.3125C1.125 13.4175 1.16083 13.5193 1.22656 13.6012C1.42156 13.8525 6.13533 19.7812 12.1016 19.7812C18.0678 19.7812 22.7741 13.8525 22.9766 13.6012C23.0423 13.5193 23.0781 13.4175 23.0781 13.3125C23.0781 13.2075 23.0423 13.1057 22.9766 13.0238ZM12.1016 18.8438C7.32033 18.8438 3.22906 14.49 2.21281 13.3125C3.22906 12.135 7.32033 7.78125 12.1016 7.78125C16.8828 7.78125 20.9741 12.135 21.9903 13.3125C20.9741 14.49 16.8828 18.8438 12.1016 18.8438Z" />
          </mask>
          <path
            d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96503 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1057 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5193 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5193 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1057 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z"
            fill="#192048"
          />
          <path
            d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3929 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66144 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
            fill="#192048"
          />
          <path
            d="M22.9766 13.0238C21.4789 11.1972 19.6713 9.64853 17.6366 8.44875L18.5366 7.545C18.5799 7.50165 18.6143 7.4502 18.6377 7.39361C18.6612 7.33699 18.6733 7.27627 18.6733 7.215C18.6733 7.15372 18.6612 7.09301 18.6377 7.03639C18.6143 6.9798 18.5799 6.92835 18.5366 6.885C18.4932 6.84165 18.4418 6.8073 18.3852 6.78383C18.3285 6.76039 18.2679 6.74831 18.2066 6.74831C18.1453 6.74831 18.0846 6.76039 18.028 6.78383C17.9713 6.8073 17.9199 6.84165 17.8766 6.885L16.7516 7.99125C15.4439 7.34186 14.0199 6.9594 12.5628 6.86625V4.6875C12.5628 4.56319 12.5134 4.44394 12.4255 4.35604C12.3376 4.26814 12.2184 4.21875 12.0941 4.21875C11.9697 4.21875 11.8505 4.26814 11.7626 4.35604C11.6747 4.44394 11.6253 4.56319 11.6253 4.6875V6.85875C10.1682 6.9519 8.74428 7.33436 7.43658 7.98375L6.31158 6.8775C6.22405 6.78998 6.10533 6.74081 5.98158 6.74081C5.85779 6.74081 5.7391 6.78998 5.65158 6.8775C5.56405 6.96503 5.51489 7.08371 5.51489 7.2075C5.51489 7.33129 5.56405 7.44998 5.65158 7.5375L6.55158 8.44125C4.52159 9.6447 2.71912 11.1958 1.22656 13.0238C1.16083 13.1057 1.125 13.2075 1.125 13.3125C1.125 13.4175 1.16083 13.5193 1.22656 13.6012C1.42156 13.8525 6.13533 19.7812 12.1016 19.7812C18.0678 19.7812 22.7741 13.8525 22.9766 13.6012C23.0423 13.5193 23.0781 13.4175 23.0781 13.3125C23.0781 13.2075 23.0423 13.1057 22.9766 13.0238ZM12.1016 18.8438C7.32033 18.8438 3.22906 14.49 2.21281 13.3125C3.22906 12.135 7.32033 7.78125 12.1016 7.78125C16.8828 7.78125 20.9741 12.135 21.9903 13.3125C20.9741 14.49 16.8828 18.8438 12.1016 18.8438Z"
            fill="#192048"
          />
          <path
            d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96503 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1057 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5193 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5193 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1057 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z"
            stroke="#192048"
            strokeWidth="0.5"
            mask="url(#path-1-outside-1_3989_20228)"
          />
          <path
            d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3929 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66144 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
            stroke="#192048"
            strokeWidth="0.5"
            mask="url(#path-1-outside-1_3989_20228)"
          />
          <path
            d="M22.9766 13.0238C21.4789 11.1972 19.6713 9.64853 17.6366 8.44875L18.5366 7.545C18.5799 7.50165 18.6143 7.4502 18.6377 7.39361C18.6612 7.33699 18.6733 7.27627 18.6733 7.215C18.6733 7.15372 18.6612 7.09301 18.6377 7.03639C18.6143 6.9798 18.5799 6.92835 18.5366 6.885C18.4932 6.84165 18.4418 6.8073 18.3852 6.78383C18.3285 6.76039 18.2679 6.74831 18.2066 6.74831C18.1453 6.74831 18.0846 6.76039 18.028 6.78383C17.9713 6.8073 17.9199 6.84165 17.8766 6.885L16.7516 7.99125C15.4439 7.34186 14.0199 6.9594 12.5628 6.86625V4.6875C12.5628 4.56319 12.5134 4.44394 12.4255 4.35604C12.3376 4.26814 12.2184 4.21875 12.0941 4.21875C11.9697 4.21875 11.8505 4.26814 11.7626 4.35604C11.6747 4.44394 11.6253 4.56319 11.6253 4.6875V6.85875C10.1682 6.9519 8.74428 7.33436 7.43658 7.98375L6.31158 6.8775C6.22405 6.78998 6.10533 6.74081 5.98158 6.74081C5.85779 6.74081 5.7391 6.78998 5.65158 6.8775C5.56405 6.96503 5.51489 7.08371 5.51489 7.2075C5.51489 7.33129 5.56405 7.44998 5.65158 7.5375L6.55158 8.44125C4.52159 9.6447 2.71912 11.1958 1.22656 13.0238C1.16083 13.1057 1.125 13.2075 1.125 13.3125C1.125 13.4175 1.16083 13.5193 1.22656 13.6012C1.42156 13.8525 6.13533 19.7812 12.1016 19.7812C18.0678 19.7812 22.7741 13.8525 22.9766 13.6012C23.0423 13.5193 23.0781 13.4175 23.0781 13.3125C23.0781 13.2075 23.0423 13.1057 22.9766 13.0238ZM12.1016 18.8438C7.32033 18.8438 3.22906 14.49 2.21281 13.3125C3.22906 12.135 7.32033 7.78125 12.1016 7.78125C16.8828 7.78125 20.9741 12.135 21.9903 13.3125C20.9741 14.49 16.8828 18.8438 12.1016 18.8438Z"
            stroke="#192048"
            strokeWidth="0.5"
            mask="url(#path-1-outside-1_3989_20228)"
          />
        </g>
        <g id="Vector_2">
          <mask
            id="path-2-outside-2_3989_20228"
            maskUnits="userSpaceOnUse"
            x="11.1"
            y="3.25"
            width="13"
            height="17"
            fill="black"
          >
            <rect fill="white" x="11.1" y="3.25" width="13" height="17" />
            <path d="M12.1 16.8093C12.5111 16.7945 12.9184 16.7066 13.3024 16.5475C13.9362 16.285 14.478 15.8404 14.8591 15.27C15.2403 14.6995 15.4437 14.0289 15.4437 13.3428C15.4428 12.4232 15.077 11.5414 14.4267 10.8912C13.8059 10.2704 12.9742 9.90885 12.1 9.87646V10.8147C12.3885 10.8289 12.674 10.8925 12.9436 11.0043C13.4062 11.1958 13.8015 11.5203 14.0796 11.9365C14.3578 12.3528 14.5062 12.8422 14.5062 13.3428C14.5053 14.0139 14.2383 14.6571 13.7638 15.1316C13.3188 15.5765 12.7255 15.8391 12.1 15.8708V16.8093Z" />
            <path d="M12.1 19.8114C17.9972 19.7961 22.6578 13.997 22.9444 13.6404C22.9472 13.6369 22.9496 13.6339 22.9516 13.6315C23.0173 13.5496 23.0531 13.4477 23.0531 13.3427C23.0531 13.2377 23.0173 13.1359 22.9516 13.054C21.4538 11.2274 19.6463 9.67875 17.6116 8.47898L18.5116 7.57523C18.5549 7.53188 18.5893 7.48043 18.6127 7.42384C18.6362 7.36722 18.6482 7.3065 18.6482 7.24523C18.6482 7.18395 18.6362 7.12324 18.6127 7.06662C18.5893 7.01003 18.5549 6.95858 18.5116 6.91523C18.4682 6.87188 18.4168 6.83753 18.3601 6.81405C18.3035 6.79062 18.2428 6.77854 18.1816 6.77854C18.1202 6.77854 18.0596 6.79062 18.0029 6.81405C17.9463 6.83753 17.8949 6.87188 17.8516 6.91523L16.7266 8.02148C15.4189 7.37209 13.9949 6.98963 12.5378 6.89648V4.71773C12.5378 4.59342 12.4884 4.47417 12.4005 4.38627C12.3199 4.30568 12.213 4.25747 12.1 4.25V7.81151C16.8712 7.82569 20.9507 12.1672 21.9653 13.3427C20.9507 14.5183 16.8712 18.8598 12.1 18.8739V19.8114Z" />
          </mask>
          <path
            d="M12.1 16.8093C12.5111 16.7945 12.9184 16.7066 13.3024 16.5475C13.9362 16.285 14.478 15.8404 14.8591 15.27C15.2403 14.6995 15.4437 14.0289 15.4437 13.3428C15.4428 12.4232 15.077 11.5414 14.4267 10.8912C13.8059 10.2704 12.9742 9.90885 12.1 9.87646V10.8147C12.3885 10.8289 12.674 10.8925 12.9436 11.0043C13.4062 11.1958 13.8015 11.5203 14.0796 11.9365C14.3578 12.3528 14.5062 12.8422 14.5062 13.3428C14.5053 14.0139 14.2383 14.6571 13.7638 15.1316C13.3188 15.5765 12.7255 15.8391 12.1 15.8708V16.8093Z"
            fill="#D0D2DB"
          />
          <path
            d="M12.1 19.8114C17.9972 19.7961 22.6578 13.997 22.9444 13.6404C22.9472 13.6369 22.9496 13.6339 22.9516 13.6315C23.0173 13.5496 23.0531 13.4477 23.0531 13.3427C23.0531 13.2377 23.0173 13.1359 22.9516 13.054C21.4538 11.2274 19.6463 9.67875 17.6116 8.47898L18.5116 7.57523C18.5549 7.53188 18.5893 7.48043 18.6127 7.42384C18.6362 7.36722 18.6482 7.3065 18.6482 7.24523C18.6482 7.18395 18.6362 7.12324 18.6127 7.06662C18.5893 7.01003 18.5549 6.95858 18.5116 6.91523C18.4682 6.87188 18.4168 6.83753 18.3601 6.81405C18.3035 6.79062 18.2428 6.77854 18.1816 6.77854C18.1202 6.77854 18.0596 6.79062 18.0029 6.81405C17.9463 6.83753 17.8949 6.87188 17.8516 6.91523L16.7266 8.02148C15.4189 7.37209 13.9949 6.98963 12.5378 6.89648V4.71773C12.5378 4.59342 12.4884 4.47417 12.4005 4.38627C12.3199 4.30568 12.213 4.25747 12.1 4.25V7.81151C16.8712 7.82569 20.9507 12.1672 21.9653 13.3427C20.9507 14.5183 16.8712 18.8598 12.1 18.8739V19.8114Z"
            fill="#D0D2DB"
          />
          <path
            d="M12.1 16.8093C12.5111 16.7945 12.9184 16.7066 13.3024 16.5475C13.9362 16.285 14.478 15.8404 14.8591 15.27C15.2403 14.6995 15.4437 14.0289 15.4437 13.3428C15.4428 12.4232 15.077 11.5414 14.4267 10.8912C13.8059 10.2704 12.9742 9.90885 12.1 9.87646V10.8147C12.3885 10.8289 12.674 10.8925 12.9436 11.0043C13.4062 11.1958 13.8015 11.5203 14.0796 11.9365C14.3578 12.3528 14.5062 12.8422 14.5062 13.3428C14.5053 14.0139 14.2383 14.6571 13.7638 15.1316C13.3188 15.5765 12.7255 15.8391 12.1 15.8708V16.8093Z"
            stroke="#D0D2DB"
            strokeWidth="0.5"
            mask="url(#path-2-outside-2_3989_20228)"
          />
          <path
            d="M12.1 19.8114C17.9972 19.7961 22.6578 13.997 22.9444 13.6404C22.9472 13.6369 22.9496 13.6339 22.9516 13.6315C23.0173 13.5496 23.0531 13.4477 23.0531 13.3427C23.0531 13.2377 23.0173 13.1359 22.9516 13.054C21.4538 11.2274 19.6463 9.67875 17.6116 8.47898L18.5116 7.57523C18.5549 7.53188 18.5893 7.48043 18.6127 7.42384C18.6362 7.36722 18.6482 7.3065 18.6482 7.24523C18.6482 7.18395 18.6362 7.12324 18.6127 7.06662C18.5893 7.01003 18.5549 6.95858 18.5116 6.91523C18.4682 6.87188 18.4168 6.83753 18.3601 6.81405C18.3035 6.79062 18.2428 6.77854 18.1816 6.77854C18.1202 6.77854 18.0596 6.79062 18.0029 6.81405C17.9463 6.83753 17.8949 6.87188 17.8516 6.91523L16.7266 8.02148C15.4189 7.37209 13.9949 6.98963 12.5378 6.89648V4.71773C12.5378 4.59342 12.4884 4.47417 12.4005 4.38627C12.3199 4.30568 12.213 4.25747 12.1 4.25V7.81151C16.8712 7.82569 20.9507 12.1672 21.9653 13.3427C20.9507 14.5183 16.8712 18.8598 12.1 18.8739V19.8114Z"
            stroke="#D0D2DB"
            strokeWidth="0.5"
            mask="url(#path-2-outside-2_3989_20228)"
          />
        </g>
      </g>
    </svg>
  );
}

export function NotVisibleIcon({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_3483_14112"
        maskUnits="userSpaceOnUse"
        x="0.0231934"
        y="3.21875"
        width="24"
        height="17"
        fill="black"
      >
        <rect fill="white" x="0.0231934" y="3.21875" width="24" height="17" />
        <path d="M22.8748 13.0238C21.3771 11.1972 19.5695 9.64853 17.5348 8.44875L18.4348 7.545C18.4781 7.50165 18.5125 7.4502 18.5359 7.39361C18.5594 7.33699 18.5715 7.27627 18.5715 7.215C18.5715 7.15372 18.5594 7.09301 18.5359 7.03639C18.5125 6.9798 18.4781 6.92835 18.4348 6.885C18.3914 6.84165 18.34 6.8073 18.2833 6.78383C18.2267 6.76039 18.166 6.74831 18.1048 6.74831C18.0435 6.74831 17.9828 6.76039 17.9262 6.78383C17.8695 6.8073 17.8181 6.84165 17.7748 6.885L16.6498 7.99125C15.3421 7.34186 13.9181 6.9594 12.461 6.86625V4.6875C12.461 4.56319 12.4116 4.44394 12.3237 4.35604C12.2358 4.26814 12.1166 4.21875 11.9923 4.21875C11.8679 4.21875 11.7487 4.26814 11.6608 4.35604C11.5729 4.44394 11.5235 4.56319 11.5235 4.6875V6.85875C10.0664 6.9519 8.64247 7.33436 7.33477 7.98375L6.20977 6.8775C6.12224 6.78998 6.00352 6.74081 5.87977 6.74081C5.75598 6.74081 5.63729 6.78998 5.54977 6.8775C5.46224 6.96502 5.41308 7.08371 5.41308 7.2075C5.41308 7.33129 5.46224 7.44998 5.54977 7.5375L6.44977 8.44125C4.41978 9.6447 2.61731 11.1958 1.12475 13.0238C1.05902 13.1056 1.02319 13.2075 1.02319 13.3125C1.02319 13.4175 1.05902 13.5194 1.12475 13.6012C1.31975 13.8525 6.03352 19.7812 11.9998 19.7812C17.966 19.7812 22.6723 13.8525 22.8748 13.6012C22.9405 13.5194 22.9763 13.4175 22.9763 13.3125C22.9763 13.2075 22.9405 13.1056 22.8748 13.0238ZM11.9998 18.8438C7.21852 18.8438 3.12725 14.49 2.111 13.3125C3.12725 12.135 7.21852 7.78125 11.9998 7.78125C16.781 7.78125 20.8723 12.135 21.8885 13.3125C20.8723 14.49 16.781 18.8438 11.9998 18.8438Z" />
      </mask>
      <path
        d="M22.8748 13.0238C21.3771 11.1972 19.5695 9.64853 17.5348 8.44875L18.4348 7.545C18.4781 7.50165 18.5125 7.4502 18.5359 7.39361C18.5594 7.33699 18.5715 7.27627 18.5715 7.215C18.5715 7.15372 18.5594 7.09301 18.5359 7.03639C18.5125 6.9798 18.4781 6.92835 18.4348 6.885C18.3914 6.84165 18.34 6.8073 18.2833 6.78383C18.2267 6.76039 18.166 6.74831 18.1048 6.74831C18.0435 6.74831 17.9828 6.76039 17.9262 6.78383C17.8695 6.8073 17.8181 6.84165 17.7748 6.885L16.6498 7.99125C15.3421 7.34186 13.9181 6.9594 12.461 6.86625V4.6875C12.461 4.56319 12.4116 4.44394 12.3237 4.35604C12.2358 4.26814 12.1166 4.21875 11.9923 4.21875C11.8679 4.21875 11.7487 4.26814 11.6608 4.35604C11.5729 4.44394 11.5235 4.56319 11.5235 4.6875V6.85875C10.0664 6.9519 8.64247 7.33436 7.33477 7.98375L6.20977 6.8775C6.12224 6.78998 6.00352 6.74081 5.87977 6.74081C5.75598 6.74081 5.63729 6.78998 5.54977 6.8775C5.46224 6.96502 5.41308 7.08371 5.41308 7.2075C5.41308 7.33129 5.46224 7.44998 5.54977 7.5375L6.44977 8.44125C4.41978 9.6447 2.61731 11.1958 1.12475 13.0238C1.05902 13.1056 1.02319 13.2075 1.02319 13.3125C1.02319 13.4175 1.05902 13.5194 1.12475 13.6012C1.31975 13.8525 6.03352 19.7812 11.9998 19.7812C17.966 19.7812 22.6723 13.8525 22.8748 13.6012C22.9405 13.5194 22.9763 13.4175 22.9763 13.3125C22.9763 13.2075 22.9405 13.1056 22.8748 13.0238ZM11.9998 18.8438C7.21852 18.8438 3.12725 14.49 2.111 13.3125C3.12725 12.135 7.21852 7.78125 11.9998 7.78125C16.781 7.78125 20.8723 12.135 21.8885 13.3125C20.8723 14.49 16.781 18.8438 11.9998 18.8438Z"
        fill="#D0D2DB"
      />
      <path
        d="M22.8748 13.0238C21.3771 11.1972 19.5695 9.64853 17.5348 8.44875L18.4348 7.545C18.4781 7.50165 18.5125 7.4502 18.5359 7.39361C18.5594 7.33699 18.5715 7.27627 18.5715 7.215C18.5715 7.15372 18.5594 7.09301 18.5359 7.03639C18.5125 6.9798 18.4781 6.92835 18.4348 6.885C18.3914 6.84165 18.34 6.8073 18.2833 6.78383C18.2267 6.76039 18.166 6.74831 18.1048 6.74831C18.0435 6.74831 17.9828 6.76039 17.9262 6.78383C17.8695 6.8073 17.8181 6.84165 17.7748 6.885L16.6498 7.99125C15.3421 7.34186 13.9181 6.9594 12.461 6.86625V4.6875C12.461 4.56319 12.4116 4.44394 12.3237 4.35604C12.2358 4.26814 12.1166 4.21875 11.9923 4.21875C11.8679 4.21875 11.7487 4.26814 11.6608 4.35604C11.5729 4.44394 11.5235 4.56319 11.5235 4.6875V6.85875C10.0664 6.9519 8.64247 7.33436 7.33477 7.98375L6.20977 6.8775C6.12224 6.78998 6.00352 6.74081 5.87977 6.74081C5.75598 6.74081 5.63729 6.78998 5.54977 6.8775C5.46224 6.96502 5.41308 7.08371 5.41308 7.2075C5.41308 7.33129 5.46224 7.44998 5.54977 7.5375L6.44977 8.44125C4.41978 9.6447 2.61731 11.1958 1.12475 13.0238C1.05902 13.1056 1.02319 13.2075 1.02319 13.3125C1.02319 13.4175 1.05902 13.5194 1.12475 13.6012C1.31975 13.8525 6.03352 19.7812 11.9998 19.7812C17.966 19.7812 22.6723 13.8525 22.8748 13.6012C22.9405 13.5194 22.9763 13.4175 22.9763 13.3125C22.9763 13.2075 22.9405 13.1056 22.8748 13.0238ZM11.9998 18.8438C7.21852 18.8438 3.12725 14.49 2.111 13.3125C3.12725 12.135 7.21852 7.78125 11.9998 7.78125C16.781 7.78125 20.8723 12.135 21.8885 13.3125C20.8723 14.49 16.781 18.8438 11.9998 18.8438Z"
        stroke="#D0D2DB"
        strokeWidth="0.5"
        mask="url(#path-1-outside-1_3483_14112)"
      />
      <mask
        id="path-2-outside-2_3483_14112"
        maskUnits="userSpaceOnUse"
        x="7.53125"
        y="8.84375"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="7.53125" y="8.84375" width="9" height="9" />
        <path d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z" />
      </mask>
      <path
        d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
        fill="#D0D2DB"
      />
      <path
        d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
        stroke="#D0D2DB"
        strokeWidth="0.5"
        mask="url(#path-2-outside-2_3483_14112)"
      />
      <rect
        x="4"
        y="6.06067"
        width="1.5"
        height="22"
        rx="0.75"
        transform="rotate(-45 4 6.06067)"
        fill="#D0D2DB"
      />
    </svg>
  );
}

export function VisibleIcon({ onClick }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_3483_14111"
        maskUnits="userSpaceOnUse"
        x="0.0234375"
        y="3.21875"
        width="24"
        height="17"
        fill="black"
      >
        <rect fill="white" x="0.0234375" y="3.21875" width="24" height="17" />
        <path d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96502 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1056 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5194 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5194 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1056 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z" />
      </mask>
      <path
        d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96502 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1056 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5194 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5194 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1056 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z"
        fill="#192048"
      />
      <path
        d="M22.875 13.0238C21.3773 11.1972 19.5697 9.64853 17.535 8.44875L18.435 7.545C18.4783 7.50165 18.5127 7.4502 18.5362 7.39361C18.5596 7.33699 18.5717 7.27627 18.5717 7.215C18.5717 7.15372 18.5596 7.09301 18.5362 7.03639C18.5127 6.9798 18.4783 6.92835 18.435 6.885C18.3917 6.84165 18.3402 6.8073 18.2836 6.78383C18.227 6.76039 18.1663 6.74831 18.105 6.74831C18.0437 6.74831 17.983 6.76039 17.9264 6.78383C17.8698 6.8073 17.8183 6.84165 17.775 6.885L16.65 7.99125C15.3423 7.34186 13.9184 6.9594 12.4613 6.86625V4.6875C12.4613 4.56319 12.4119 4.44394 12.3239 4.35604C12.236 4.26814 12.1168 4.21875 11.9925 4.21875C11.8682 4.21875 11.749 4.26814 11.6611 4.35604C11.5732 4.44394 11.5238 4.56319 11.5238 4.6875V6.85875C10.0667 6.9519 8.64271 7.33436 7.33501 7.98375L6.21001 6.8775C6.12249 6.78998 6.00376 6.74081 5.88001 6.74081C5.75623 6.74081 5.63754 6.78998 5.55001 6.8775C5.46249 6.96502 5.41333 7.08371 5.41333 7.2075C5.41333 7.33129 5.46249 7.44998 5.55001 7.5375L6.45001 8.44125C4.42003 9.6447 2.61756 11.1958 1.125 13.0238C1.05926 13.1056 1.02344 13.2075 1.02344 13.3125C1.02344 13.4175 1.05926 13.5194 1.125 13.6012C1.32 13.8525 6.03376 19.7812 12 19.7812C17.9663 19.7812 22.6725 13.8525 22.875 13.6012C22.9408 13.5194 22.9766 13.4175 22.9766 13.3125C22.9766 13.2075 22.9408 13.1056 22.875 13.0238ZM12 18.8438C7.21876 18.8438 3.1275 14.49 2.11125 13.3125C3.1275 12.135 7.21876 7.78125 12 7.78125C16.7813 7.78125 20.8725 12.135 21.8888 13.3125C20.8725 14.49 16.7813 18.8438 12 18.8438Z"
        stroke="#192048"
        strokeWidth="0.5"
        mask="url(#path-1-outside-1_3483_14111)"
      />
      <mask
        id="path-2-outside-2_3483_14111"
        maskUnits="userSpaceOnUse"
        x="7.53125"
        y="8.84375"
        width="9"
        height="9"
        fill="black"
      >
        <rect fill="white" x="7.53125" y="8.84375" width="9" height="9" />
        <path d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z" />
      </mask>
      <path
        d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
        fill="#192048"
      />
      <path
        d="M12 9.84375C11.3139 9.84375 10.6433 10.0472 10.0729 10.4283C9.50244 10.8095 9.05783 11.3512 8.7953 11.9851C8.53276 12.6189 8.46406 13.3164 8.5979 13.9892C8.73177 14.6621 9.06211 15.2802 9.54725 15.7653C10.0323 16.2504 10.6504 16.5808 11.3233 16.7146C11.9961 16.8485 12.6936 16.7797 13.3274 16.5172C13.9613 16.2547 14.503 15.8101 14.8842 15.2396C15.2653 14.6692 15.4688 13.9986 15.4688 13.3125C15.4678 12.3928 15.102 11.5111 14.4517 10.8608C13.8014 10.2105 12.9197 9.84472 12 9.84375ZM12 15.8438C11.4994 15.8438 11.01 15.6953 10.5937 15.4172C10.1775 15.139 9.85302 14.7437 9.66143 14.2812C9.46985 13.8186 9.41975 13.3097 9.5174 12.8187C9.61508 12.3277 9.85613 11.8766 10.2101 11.5226C10.5641 11.1686 11.0152 10.9275 11.5062 10.8299C11.9972 10.7322 12.5061 10.7823 12.9687 10.9739C13.4312 11.1655 13.8265 11.49 14.1047 11.9062C14.3828 12.3225 14.5313 12.8119 14.5313 13.3125C14.5303 13.9835 14.2633 14.6268 13.7888 15.1013C13.3143 15.5757 12.671 15.8428 12 15.8438Z"
        stroke="#192048"
        strokeWidth="0.5"
        mask="url(#path-2-outside-2_3483_14111)"
      />
    </svg>
  );
}

export function ReplaceIcon({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.333 2H2.667A.667.667 0 0 0 2 2.667v2.666c0 .369.298.667.667.667h2.666A.667.667 0 0 0 6 5.333V2.667A.667.667 0 0 0 5.333 2zM13.333 10h-2.666a.667.667 0 0 0-.667.667v2.666c0 .368.299.667.667.667h2.666a.667.667 0 0 0 .667-.667v-2.666a.667.667 0 0 0-.667-.667zM14 7.333v-2A1.334 1.334 0 0 0 12.667 4h-4l2 2m0-4-2 2M2 8.667v2A1.333 1.333 0 0 0 3.333 12h4l-2-2m0 4 2-2"
        stroke={color}
        strokeWidth="1.333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ZoomIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#zdojvdx6fa)">
        <path
          d="M8.347 1.806a.5.5 0 0 0-.687-.001L6.176 3.2a.5.5 0 0 1-.685-.728l1.483-1.396a1.5 1.5 0 0 1 2.06.003l1.471 1.394a.5.5 0 0 1-.688.726l-1.47-1.394zM3.18 5.469a.5.5 0 0 1 .02.706L1.807 7.66a.5.5 0 0 0 0 .686l1.395 1.472a.5.5 0 1 1-.726.688L1.081 9.033a1.5 1.5 0 0 1-.003-2.06l1.396-1.482a.5.5 0 0 1 .707-.022zm9.637.008a.5.5 0 0 1 .707.018l1.399 1.476a1.5 1.5 0 0 1 0 2.064l-1.4 1.476a.499.499 0 0 1-.87-.329.5.5 0 0 1 .145-.36l1.399-1.475a.5.5 0 0 0 0-.688L12.8 6.183a.5.5 0 0 1 .019-.706h-.001zm-2.29 7.342a.5.5 0 0 1-.018.706l-1.475 1.399a1.5 1.5 0 0 1-2.063 0l-1.477-1.399a.5.5 0 1 1 .688-.725l1.477 1.399a.5.5 0 0 0 .688 0L9.823 12.8a.5.5 0 0 1 .706.018zM6.5 4.667A1.833 1.833 0 0 0 4.667 6.5v3A1.834 1.834 0 0 0 6.5 11.333h3A1.833 1.833 0 0 0 11.333 9.5v-3A1.833 1.833 0 0 0 9.5 4.667h-3z"
          fill="#192048"
        />
      </g>
      <defs>
        <clipPath id="zdojvdx6fa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BackButtonIcon() {
  return (
    <svg
      width="7"
      height="17"
      viewBox="0 0 7 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.837 16.18a.897.897 0 0 1-.431-.11 1.032 1.032 0 0 1-.349-.313L.227 8.9A1.247 1.247 0 0 1 0 8.175c0-.265.08-.522.227-.726l5-6.856a.97.97 0 0 1 .679-.408.92.92 0 0 1 .731.259c.205.194.333.473.358.775.024.302-.058.603-.228.836L2.297 8.18l4.32 6.126c.123.167.2.372.224.588.024.217-.007.437-.089.634a1.106 1.106 0 0 1-.374.479.909.909 0 0 1-.54.173z"
        fill="#192048"
      />
    </svg>
  );
}

export function EmptySetIcon() {
  return (
    <svg
      width="147"
      height="143"
      viewBox="0 0 147 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#d2962xk33a)">
        <path
          d="M92.936 32.138H53.49a3.394 3.394 0 0 0-3.391 3.39v87.669l-.452.138-9.677 2.964a1.81 1.81 0 0 1-2.258-1.2L8.928 31.077a1.809 1.809 0 0 1 1.2-2.258l14.912-4.566 43.23-13.232 14.912-4.566a1.806 1.806 0 0 1 2.258 1.198l7.358 24.032.138.453z"
          fill="url(#05pqn0polb)"
        />
        <path
          d="M101.543 31.684 92.675 2.72A3.841 3.841 0 0 0 87.876.17L66.911 6.587 23.683 19.822l-20.965 6.42a3.846 3.846 0 0 0-2.55 4.798l30.308 98.99a3.848 3.848 0 0 0 3.675 2.719c.381 0 .76-.057 1.124-.17l14.371-4.398.452-.14v-.473l-.452.138-14.505 4.442a3.393 3.393 0 0 1-4.233-2.249L.603 30.907a3.386 3.386 0 0 1 2.249-4.234l20.965-6.42L67.045 7.022 88.01.601a3.394 3.394 0 0 1 4.234 2.25l8.826 28.833.14.452h.471l-.138-.452z"
          fill="#3F3D56"
        />
        <path
          d="M27.73 28.893a2.038 2.038 0 0 1-1.946-1.44l-2.912-9.51a2.033 2.033 0 0 1 1.35-2.54l39.77-12.175a2.036 2.036 0 0 1 2.54 1.35l2.912 9.51a2.037 2.037 0 0 1-1.35 2.54l-39.77 12.176a2.03 2.03 0 0 1-.594.089z"
          fill="#CAECFF"
        />
        <path
          d="M42.982 10.162a4.52 4.52 0 1 0 0-9.041 4.52 4.52 0 0 0 0 9.041z"
          fill="#7BD2FC"
        />
        <path
          d="M42.981 8.504a2.863 2.863 0 1 0 0-5.725 2.863 2.863 0 0 0 0 5.725z"
          fill="#fff"
        />
        <path
          d="M136.219 131.595H59.818a1.926 1.926 0 0 1-1.921-1.921V38.128a1.923 1.923 0 0 1 1.92-1.922h76.402a1.922 1.922 0 0 1 1.922 1.922v91.546a1.924 1.924 0 0 1-1.922 1.921z"
          fill="url(#yeqqmyon4c)"
        />
        <path
          d="M101.071 31.686H53.489a3.848 3.848 0 0 0-3.842 3.842v92.179l.452-.138v-92.04a3.395 3.395 0 0 1 3.39-3.391h47.722l-.14-.452zm41.478 0h-89.06a3.848 3.848 0 0 0-3.842 3.842v103.526a3.848 3.848 0 0 0 3.842 3.843h89.06a3.85 3.85 0 0 0 3.843-3.843V35.528a3.852 3.852 0 0 0-3.843-3.842zm3.391 107.368a3.395 3.395 0 0 1-3.391 3.391h-89.06a3.397 3.397 0 0 1-3.39-3.391V35.528a3.395 3.395 0 0 1 3.39-3.39h89.06a3.392 3.392 0 0 1 3.391 3.39v103.526z"
          fill="#3F3D56"
        />
        <path
          d="M118.815 41.631H77.224a2.037 2.037 0 0 1-2.035-2.034V29.65a2.037 2.037 0 0 1 2.035-2.035h41.591a2.039 2.039 0 0 1 2.034 2.035v9.946a2.035 2.035 0 0 1-2.034 2.034z"
          fill="#CAECFF"
        />
        <path
          d="M98.019 28.294a4.52 4.52 0 1 0-.001-9.042 4.52 4.52 0 0 0 0 9.042z"
          fill="#7BD2FC"
        />
        <path
          d="M98.018 26.528a2.754 2.754 0 1 0 0-5.507 2.754 2.754 0 0 0 0 5.507z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="05pqn0polb"
          x1="34.075"
          y1="14.175"
          x2="130.495"
          y2="91.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF3FE" />
          <stop offset="1" stopColor="#ECF3FE" stopOpacity=".16" />
        </linearGradient>
        <linearGradient
          id="yeqqmyon4c"
          x1="81.97"
          y1="42.407"
          x2="160.397"
          y2="118.096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ECF3FE" />
          <stop offset="1" stopColor="#ECF3FE" stopOpacity=".16" />
        </linearGradient>
        <clipPath id="d2962xk33a">
          <path fill="#fff" d="M0 0h146.391v142.896H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

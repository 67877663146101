import { FC, forwardRef, useRef, useState } from "react";
import { TextboxComponentProps } from "../../interface";
import { defaultTextboxComponentProps } from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import {
  getTextboxClassStyle,
  getTextboxParentStyle,
  getTextboxStyle,
} from "../../constants/globalFunctions";
import { TextBoxComponent as SyncfusionTextboxComponent, TextBoxModel } from "@syncfusion/ej2-react-inputs";

const TextBoxComponent: FC<TextboxComponentProps & TextBoxModel> = forwardRef((props: TextboxComponentProps & TextBoxModel, ref) => {
  const textRef = ref || useRef();

  const [showPassword, setShowPassword] = useState(false);

  const useStyles = createUseStyles({
    eInput: getTextboxClassStyle(),
    parentTextBoxComponentStyle: getTextboxParentStyle(
      props.widthInPercentage as string,
      props.margin as string
    ),
    passwordInputContainer: {
      //removing flex class as input box inside query builder is disotred
      // display: 'flex',
      // flexDirection: 'column',
      position: 'relative',
      // gap: 'var(--size-16)'
    },
    togglePasswordButton: {
      position: 'absolute',
      right: 'var(--size-10)',
      cursor: 'pointer',
    },
    typePassword: {
      '& .e-clear-icon': {
        alignItems: 'flex-start',
        minWidth: 'var(--size-50) !important',
      },
    },
  });
  const classes = useStyles() as any;

  const TextBoxComponentStyle = getTextboxStyle(props.disabled as boolean);

  const handleClickTogglePassword = (e) => {
    e.stopPropagation();
    setShowPassword(prevState => !prevState);
  }

  return (
    <div className={classes.parentTextBoxComponentStyle}>
      {props.label && (
        <div style={{ marginBottom: "8px" }}>
          <span>{props.label}</span>
        </div>
      )}
      <div className={classes.passwordInputContainer}>
        <SyncfusionTextboxComponent
          {...props}
          type={showPassword ? 'text' : props.type}
          cssClass={`e-input ${props.isRTL && "e-rtl"} ${props.disabled && "e-disabled"} ${props.isSuccess && "e-success"
            } ${props.isWarning && "e-warning"} ${props.isSmall && "e-small"} ${props.isError && "e-error"
            } ${classes.eInput} ${props.type == 'password' ? classes.typePassword : ''} ${classes.textBoxCss}`}
          style={TextBoxComponentStyle}
          ref={textRef}
        />
        {props.type == 'password' && (
          <div 
            className={`${textRef.current?.floatLabelType == 'Always' ? 'top-9' : 'top-2'} ${classes.togglePasswordButton}`} 
            onClick={handleClickTogglePassword}
          >
            {showPassword ? <img src="/assets/icons/EyeView.svg" alt="Show" /> : <img src="/assets/icons/EyeViewHide.svg" alt="Hide" />}
          </div>
        )}
      </div>
    </div>
  );
});
TextBoxComponent.defaultProps = defaultTextboxComponentProps as any;
export default TextBoxComponent;

import React, { createRef, forwardRef, useId } from "react";
import "./index.css";
import { SlickgridReact } from "slickgrid-react";
import "@slickgrid-universal/common/dist/styles/css/slickgrid-theme-material.css";

const SlickGrid = forwardRef((props, ref) => {
  const id = useId();
  const gridRef = ref || createRef(null);

  const gridOptions = {
    autoResize: {
      container: `#${id.replace(/:/g, "_")}`,
      applyResizeToContainer: true,
      calculateAvailableSizeBy: "container",
    },
    ...(props.gridOptions || {}),
  };

  const onReactGridCreated = ($event) => {
    if (props.onReactGridCreated) {
      props.onReactGridCreated($event);
    }
  };
  return (
    <div id={id.replace(/:/g, "_")} className="h-full cube-slick-grid">
      <SlickgridReact
        {...props}
        ref={gridRef}
        gridId={`${id.replace(/:/g, "_")}_grid18`}
        gridOptions={gridOptions}
        onReactGridCreated={onReactGridCreated}
      />
    </div>
  );
});
export default SlickGrid;

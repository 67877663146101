const _getResourceTypes = () => ({
  RESOURCE_TYPE_ISSUE: 'RESOURCE_TYPE_ISSUE',
  RESOURCE_TYPE_ESTIMATE: 'RESOURCE_TYPE_ESTIMATE',
  RESOURCE_TYPE_FILE: 'RESOURCE_TYPE_FILE',
  RESOURCE_TYPE_PROJECT: 'RESOURCE_TYPE_PROJECT',
  RESOURCE_TYPE_PORTAL: 'RESOURCE_TYPE_PORTAL',
  RESOURCE_TYPE_ORG: 'RESOURCE_TYPE_ORG',
  RESOURCE_TYPE_TRANSMITTAL: 'RESOURCE_TYPE_TRANSMITTAL',
  RESOURCE_TYPE_SPACE: 'RESOURCE_TYPE_SPACE',
  RESOURCE_TYPE_MERGED_FILE: 'RESOURCE_TYPE_MERGED_FILE',
  RESOURCE_TYPE_FOLDER: 'RESOURCE_TYPE_FOLDER',
  RESOURCE_TYPE_TASKS: 'RESOURCE_TYPE_TASKS',
  RESOURCE_TYPE_SPACE_ROOT: 'RESOURCE_TYPE_SPACE_ROOT',
  RESOURCE_TYPE_SPACE_TOGGLE: 'RESOURCE_TYPE_SPACE_TOGGLE',
  RESOURCE_TYPE_SHORTCUT_FILE: 'RESOURCE_TYPE_SHORTCUT_FILE',
  RESOURCE_TYPE_SHORTCUT_MERGED_FILE: 'RESOURCE_TYPE_SHORTCUT_MERGED_FILE',
  RESOURCE_TYPE_SCHEDULE: 'RESOURCE_TYPE_SCHEDULE',
  RESOURCE_TYPE_CONTRACT: 'RESOURCE_TYPE_CONTRACT',
  RESOURCE_TYPE_GROUP: 'RESOURCE_TYPE_GROUP',
  RESOURCE_TYPE_EMBED: 'RESOURCE_TYPE_EMBED'
})

module.exports = {
  FOLDER_TYPE_DRAWINGS: {
    id: 0,
    name: 'Models & Drawings'
  },
  FOLDER_TYPE_ISSUES: {
    id: 1,
    name: 'Issues'
  },
  FOLDER_TYPE_ESTIMATES: {
    id: 2,
    name: 'Estimates'
  },
  FOLDER_TYPE_TASKS: {
    id: 3,
    name: 'Tasks'
  },
  FOLDER_TYPE_OTHER: {
    id: 4,
    name: 'Other Documents'
  },
  _getResourceTypes
}

import React, { FC } from "react";
import { RadioButtonComponentProps } from "../../interface";
import { RadioButtonComponent as SyncfusionRadioButtonComponent, RadioButtonModel } from "@syncfusion/ej2-react-buttons";
import { defaultRadioButtonComponentProps } from "../../constants/globalVariable";
import { createUseStyles } from "react-jss";
import { getRadioButtonComponentStyle } from "../../constants/globalFunctions";

const RadioButtonComponent: FC<RadioButtonComponentProps & RadioButtonModel> = (props: RadioButtonComponentProps & RadioButtonModel) => {
  /******************* 
@Purpose : Radio Component
@Parameter : RadioButtonComponentProps
@Author : Techture
 *******************/

  const useStyles = createUseStyles({
    radiobuttonGradient: getRadioButtonComponentStyle(
      props.borderColor as string,
      props.borderColorFocus as string,
      props.bgColor as string,
      props.gradientColor1 as string,
      props.gradientColor2 as string
    ),
  });

  const classes = useStyles() as any;

  return (
    <SyncfusionRadioButtonComponent
      {...props}
      cssClass={classes.radiobuttonGradient}
    ></SyncfusionRadioButtonComponent>
  );
};
RadioButtonComponent.defaultProps = defaultRadioButtonComponentProps;
export default RadioButtonComponent;

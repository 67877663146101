import React, { FC, forwardRef, useRef } from "react";
import { CheckboxComponentProps } from "../../interface";

import { CheckBoxComponent as SyncfusionCheckboxComponent, CheckBoxModel } from "@syncfusion/ej2-react-buttons";
import { defaultCheckboxComponentProps } from "../../constants/globalVariable";

import { createUseStyles } from "react-jss";
import { getCheckBoxComponentStyle } from "../../constants/globalFunctions";

const CheckboxComponent: FC<CheckboxComponentProps & CheckBoxModel> = forwardRef((props: CheckboxComponentProps & CheckBoxModel,ref) => {
  /******************* 
@Purpose : Checkbox Component
@Parameter : CheckboxComponentProps
@Author : Techture
 *******************/
 const checkBoxRef = ref ||  useRef(null);
  const useStyles = createUseStyles({
    checkboxGradient: getCheckBoxComponentStyle(
      props.borderColor as string,
      props.borderColorFocus as string,
      props.bgColor as string,
      props.gradientColor1 as string,
      props.gradientColor2 as string
    ),
  });

  const classes = useStyles() as any;

  return (
    <SyncfusionCheckboxComponent
    {...props}
    ref={checkBoxRef}
    cssClass={classes.checkboxGradient}
    ></SyncfusionCheckboxComponent>
  );
});
// CheckboxComponent.defaultProps = defaultCheckboxComponentProps;
export default CheckboxComponent;

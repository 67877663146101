import { useRef } from "react";
import { useParams } from "react-router";
import { Query } from "@syncfusion/ej2-data";
import { formSectionType } from "../../components/CreateInspectionAndProgress/constants";
import useForms from "../../components/CreateInspectionAndProgress/useForms";
import { getIconFromFieldType } from "../../components/PortalSetings/Configuration/FormsAndFields/FormTemplate/constants";
import { optionsViewType } from "../../components/PortalSetings/Configuration/FormsAndFields/FormTemplate/constant.helper";
import { BiLinkExternal } from "react-icons/bi";
import { PlusIcon } from "../../components/StickySidebarViewer/assets/selectionIcons";
// import { getFileIcon } from "../../../../mobile/src/Components/Services/globalFunction";
import { teamsService, locationService } from "../dataManager";
import TextBoxComponent from "@CubeComponents/form/TextBoxComponent";
import RadioButtonComponent from "@CubeComponents/form/RadioButtonComponent";
import CheckboxComponent from "@CubeComponents/form/CheckboxComponent";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  NumericTextBoxComponent,
  TextAreaComponent,
} from "@syncfusion/ej2-react-inputs";
import {
  DropDownListComponent,
  DropDownTreeComponent,
} from "@syncfusion/ej2-react-dropdowns";
import MultiSelectComponent from "../../cubeComponent/form/MultiselectComponent";
import IconComponent from "@CubeComponents/icons";
import ButtonComponent from "@CubeComponents/form/ButtonComponent";
import { BUTTON_TYPES } from "@CubeComponents/constants/globalVariable";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  formCategoryObject,
  presetSectionsCodeObject,
} from "../../components/PortalSetings/Configuration/FormsAndFields/constants";
import { convertDate } from "../luxonHelper";

// const { getPermissions } = useForms()
const getPermissions = () => {};

const useCustomFields = () => {
  const params = useParams();
  const locationQuery = new Query().addParams("projectId", params.projectId);

  const handleFileOpen = (awsKey, index) => {
    let url = null;
    let target = "_self";
    url = `${process.env.REACT_APP_CUBE_BACKEND}/fileSystemServer/getInspectionAttachmentFile/${awsKey}`;
    target = "_blank";

    return (
      <a href={url} target={target} aria-label="attachment">
        <BiLinkExternal />
      </a>
    );
  };

  const validateFormWithId = (id, template, formObject) => {
    const rules = [];
    template?.forEach((section) => {
      section.questionFields.forEach((item) => {
        rules.push(`${id}_${item._id}`);
      });
    });
    let valid = true;
    rules.forEach((name) => {
      const ruleValid = formObject?.current?.validate(name);
      valid = valid && ruleValid;
    });
    return valid;
  };

  const addValidationToInputFields = ({
    inspectionData,
    template = [],
    formObject,
    rows = [],
  }) => {
    const allSectionsInCurrentLevel = [];
    if (inspectionData) {
      inspectionData.formFlow[inspectionData.currentLevelOfForm].dataSource.map(
        (data) => {
          if (data.type === "Fill") {
            data.sections.map((section) =>
              allSectionsInCurrentLevel.push(section._id),
            );
          } else if (data.type === "Modify") {
            data.sections.map((section) => {
              if (modifySection.includes(section._id)) {
                allSectionsInCurrentLevel.push(section._id);
              }
            });
          }
        },
      );
      template = inspectionData.selectedForm.template;
    } else {
      template.forEach((section) =>
        allSectionsInCurrentLevel.push(section._id),
      );
    }

    const options = { rules: {} };
    template.forEach((section) => {
      if (
        section.sectionCode == presetSectionsCodeObject.tableSection &&
        allSectionsInCurrentLevel.includes(section._id)
      ) {
        section.questionFields.forEach((eachField) => {
          section.tableDataSource.forEach((row) => {
            const excludedTypes = ["users", "fileUpload"];
            const isSelectWithListView =
              (eachField.fieldType === "multi-select" ||
                eachField.fieldType === "single-select") &&
              eachField.viewType === optionsViewType.LIST;
            if (
              !excludedTypes.includes(eachField.fieldType) &&
              !isSelectWithListView
            ) {
              const ruleObj = {};
              const ruleName = `${row.rowId}${eachField._id}`;
              if (eachField.isMandatory === true) {
                ruleObj.required = [true, "* Required"];
              }
              if (["single-line", "multi-line"].includes(eachField.fieldType)) {
                ruleObj.minLength = eachField.minFieldLimit ?? 0;
                if (eachField.maxFieldLimit) {
                  ruleObj.maxLength = eachField.maxFieldLimit;
                }
              } else if (["date-time"].includes(eachField.fieldType)) {
                ruleObj.regex = [
                  "^([0-2][0-9]|3[0-1])/((0[1-9])|(1[0-2]))/([1-9][0-9]{3}) ((0[1-9])|(1[0-2])):([0-5][0-9]):(AM|PM)$",
                  "Please enter a valid date",
                ];
              }
              if (rows) {
                rows.forEach((row) => {
                  if (!row.isFolder) {
                    options.rules[`${row.id}_${ruleName}`] = {
                      ...ruleObj,
                    };
                  }
                });
              } else {
                options.rules[ruleName] = {
                  ...ruleObj,
                };
              }
            }
          });
        });
      } else if (section.questionFields) {
        if (allSectionsInCurrentLevel.includes(section._id)) {
          section.questionFields.some((eachField) => {
            const excludedTypes = ["users", "fileUpload"];
            const isSelectWithListView =
              (eachField.fieldType === "multi-select" ||
                eachField.fieldType === "single-select") &&
              eachField.viewType === optionsViewType.LIST;
            if (
              !excludedTypes.includes(eachField.fieldType) &&
              !isSelectWithListView
            ) {
              const ruleName = `${eachField._id}`;
              const ruleObj = {};
              if (eachField.isMandatory === true) {
                ruleObj.required = [true, "* Required"];
              }
              if (["single-line", "multi-line"].includes(eachField.fieldType)) {
                ruleObj.minLength = eachField.minFieldLimit ?? 0;
                if (eachField.maxFieldLimit) {
                  ruleObj.maxLength = eachField.maxFieldLimit;
                }
              } else if (["date-time"].includes(eachField.fieldType)) {
                ruleObj.regex = [
                  "^([0-2][0-9]|3[0-1])/((0[1-9])|(1[0-2]))/([1-9][0-9]{3}) ((0[1-9])|(1[0-2])):([0-5][0-9]):(AM|PM)$",
                  "Please enter a valid date",
                ];
              }
              if (rows) {
                rows.forEach((row) => {
                  if (!row.isFolder) {
                    options.rules[`${row.id}_${ruleName}`] = {
                      ...ruleObj,
                    };
                  }
                });
              } else {
                options.rules[ruleName] = {
                  ...ruleObj,
                };
              }
            }
          });
        }
      }
    });
    formObject.current = new FormValidator("#formData", options);
  };

  const getFieldAnsComponent = ({
    args,
    eachField,
    fieldIndex,
    sectionIndex,
    section = {},
    source,
    tableRowIndex,
    tableRowId = null,
    answerValue = null,
    formObject,
    disabled = false,
    width,
    placeholder = "",
    getUserCardAndChip,
    openAddUserModal = () => {},
    handleFileUploadChange = () => {},
    handleRemoveUploadedFile,
    inspectionData,
    handleCustomFieldData,
    multiTextRows = 5,
    dateFieldFocused,
  }) => {
    const {
      _id,
      fieldType,
      fieldLabel,
      answerChoices,
      placeholderText,
      maxFieldLimit,
      minFieldLimit,
      lookUpAnsFieldType,
      minFieldRange,
      maxFieldRange,
      isMandatory = false,
      viewType = optionsViewType.LIST,
      selectionScope,
      dataToDisplay = [],
    } = eachField;
    let answer = eachField.answer ?? {};
    if (source == formSectionType.TABLE_SECTION) {
      answer = section.tableDataSource[tableRowIndex]?.[_id] ?? {};
    }
    const { permissions } = section;
    const sectionId = section._id;
    const questionId = _id;
    const isCurrentUserAllowedToFill = permissions
      ? getPermissions(permissions, "Fill")
      : true;
    const isCurrentUserAllowedToModify = permissions
      ? getPermissions(permissions, "Modify")
      : true;
    let name = tableRowId ? `${tableRowId}${questionId}` : questionId;
    name = args && args.id ? `${args.id}_${name}` : name;
    const enabled =
      (isCurrentUserAllowedToFill || isCurrentUserAllowedToModify) && !disabled;
    placeholder = placeholder || placeholderText;
    width = width ?? "100%";
    answerValue = answerValue || answer.value;

    const errorLabelId = `error-label-id_${name}`;
    let fieldComponent = <></>;

    if (fieldType == "single-line") {
      fieldComponent = (
        <TextBoxComponent
          className={`field_${_id}`}
          type="text"
          enabled={enabled}
          data-msg-containerid={errorLabelId}
          value={answerValue ?? ""}
          change={(e) => {
            handleInputChange({
              e,
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              minFieldLimit,
              maxFieldLimit,
              section,
              tableRowIndex,
              source,
              handleCustomFieldData,
              name,
              formObject,
            });
          }}
          placeholder={placeholder}
          width={width}
          name={name}
          input={(e) => {
            formObject?.current?.validate(name);
          }}
        />
      );
    }
    if (fieldType == "multi-line") {
      fieldComponent = (
        <div>
          <TextAreaComponent
            style={{
              border: "1px solid #19204880",
              outline: "none",
              padding: "0.42rem 0 0.42rem 0.5rem",
              height: "100%",
              color: "black",
            }}
            type="textarea"
            data-msg-containerid={errorLabelId}
            disabled={!enabled}
            rows={multiTextRows}
            name={name}
            value={answerValue ?? ""}
            placeholder={placeholder ?? ""}
            defaultValue={answerValue ?? ""}
            change={(e) => {
              handleInputChange({
                e,
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                minFieldLimit,
                maxFieldLimit,
                section,
                tableRowIndex,
                source,
                handleCustomFieldData,
                name,
                formObject,
              });
            }}
            onChange={(e) => {
              // handleInputChange({
              //   e,
              //   args,
              //   fieldIndex,
              //   sectionIndex,
              //   eachField,
              //   minFieldLimit,
              //   maxFieldLimit,
              //   section,
              //   tableRowIndex,
              //   source,
              //   handleCustomFieldData,
              //   name,
              //   formObject,
              // });
              formObject?.current?.validate(name);
            }}
          />
        </div>
      );
    }
    if (fieldType == "single-select") {
      const dropDownDataSource = answerChoices.map((choices) => ({
        name: choices,
        value: choices,
      }));
      fieldComponent =
        viewType == optionsViewType.LIST ? (
          <div className="flex flex-col gap-3">
            {answerChoices.length > 0 &&
              answerChoices.map((choice, index) => (
                <RadioButtonComponent
                  key={index}
                  data-msg-containerid={errorLabelId}
                  disabled={!enabled}
                  change={(e) =>
                    handleInputChange({
                      e,
                      args,
                      fieldIndex,
                      sectionIndex,
                      eachField,
                      section,
                      tableRowIndex,
                      source,
                      handleCustomFieldData,
                      name,
                      formObject,
                    })
                  }
                  checked={answerValue == choice}
                  label={choice}
                  name={name}
                />
              ))}
          </div>
        ) : (
          <DropDownListComponent
            className={`field_${_id}`}
            enabled={enabled}
            width={width}
            value={answerValue}
            data-msg-containerid={errorLabelId}
            onChange={(e) => {
              handleInputChange({
                e,
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                section,
                tableRowIndex,
                source,
                handleCustomFieldData,
                name,
                formObject,
              });
              formObject?.current?.validate(name);
            }}
            placeholder={placeholder ?? ""}
            fields={{ text: "name", value: "value" }}
            dataSource={dropDownDataSource}
            name={name}
          />
        );
    }
    if (fieldType == "multi-select") {
      const dropDownDataSource = answerChoices.map((choices) => ({
        name: choices,
        value: choices,
      }));
      fieldComponent =
        viewType == optionsViewType.LIST ? (
          <div className="flex flex-col gap-3">
            {answerChoices?.length > 0 &&
              answerChoices.map((choice, index) => (
                <CheckboxComponent
                  key={index}
                  data-msg-containerid={errorLabelId}
                  className={`field_${_id}`}
                  borderColor={!enabled ? "var(--border-gray)" : ""}
                  disabled={!enabled}
                  checked={
                    typeof answerValue == "object" &&
                    answerValue.includes(choice)
                  }
                  change={(e) => {
                    handleInputChange({
                      e,
                      args,
                      fieldIndex,
                      sectionIndex,
                      eachField,
                      section,
                      tableRowIndex,
                      source,
                      handleCustomFieldData,
                      name,
                      formObject,
                    });
                  }}
                  label={choice}
                  name={name}
                />
              ))}
          </div>
        ) : (
          <MultiSelectComponent
            className={`field_${_id}`}
            enabled={enabled}
            placeholder={placeholder ?? ""}
            value={answerValue}
            fields={{ text: "name", value: "value" }}
            data-msg-containerid={errorLabelId}
            dataSource={dropDownDataSource}
            width={width}
            removed={(e) => {
              handleInputChange({
                e,
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                section,
                tableRowIndex,
                source,
                handleCustomFieldData,
                name,
                formObject,
              });
              formObject?.current?.validate(name);
            }}
            select={(e) => {
              handleInputChange({
                e,
                args,
                fieldIndex,
                sectionIndex,
                eachField,
                section,
                tableRowIndex,
                source,
                handleCustomFieldData,
                name,
                formObject,
              });
            }}
            close={() => {
              formObject?.current?.validate(name);
            }}
            name={name}
            changeOnBlur={false}
          />
        );
    }
    if (fieldType == "date-time") {
      const timeStamp = answer?.value ?? Date.now();
      const min = minFieldRange
        ? new Date(minFieldRange)
        : selectionScope == "futureDateTime"
          ? new Date(timeStamp)
          : new Date(0);

      const max = maxFieldRange && new Date(maxFieldRange);
      fieldComponent =
        selectionScope == "currentDateTime" ? (
          enabled ? (
            <div
              className={`${
                answer?.value
                  ? "cursor-not-allowed pointer-events-none"
                  : "cursor-pointer"
              } border border-slate-400 w-1/2 h-8 flex items-center p-2 field_${_id}`}
              onClick={(e) =>
                handleInputChange({
                  e,
                  args,
                  fieldIndex,
                  sectionIndex,
                  eachField,
                  minFieldLimit,
                  maxFieldLimit,
                  section,
                  tableRowIndex,
                  source,
                  handleCustomFieldData,
                })
              }
              name={name}
            >
              {answer?.value ? (
                convertDate(Number(answer?.value), "dateForTooltip")
              ) : (
                <span className="text-xs text-slate-500">
                  Click here to set date
                </span>
              )}
            </div>
          ) : (
            <div className="cursor-not-allowed pointer-events-none border bg-gray-100 border-slate-400 w-1/2 h-8 flex items-center p-2">
              {answer?.value &&
                convertDate(Number(answer?.value), "dateForTooltip")}
            </div>
          )
        ) : (
          <div>
            <DateTimePickerComponent
              enabled={
                enabled &&
                !(selectionScope == "currentDateTime" && answer?.value)
              }
              className={`field_${_id}`}
              min={min}
              max={max}
              data-msg-containerid={errorLabelId}
              value={answerValue ? new Date(answerValue) : null}
              id={`datetimepicker_${_id}`}
              width={width}
              change={(e) =>
                handleInputChange({
                  e,
                  args,
                  fieldIndex,
                  sectionIndex,
                  eachField,
                  section,
                  tableRowIndex,
                  source,
                  handleCustomFieldData,
                  name,
                  formObject,
                })
              }
              placeholder={placeholder ?? ""}
              format="dd/MM/yyyy hh:mm:a"
              name={name}
              onChange={(e) => {
                formObject?.current?.validate(name);
              }}
            />
          </div>
        );
    }
    if (fieldType == "look-up") {
      if (lookUpAnsFieldType == "Location") {
        const selectedValue =
          (Array.isArray(answerValue) && answerValue?.map((ans) => ans.id)) ||
          [];
        fieldComponent = (
          <div>
            <DropDownTreeComponent
              enabled={enabled}
              className={`field_${_id}`}
              width={width}
              showCheckBox
              placeholder={placeholder ?? ""}
              data-msg-containerid={errorLabelId}
              allowMultiSelection={false}
              value={selectedValue}
              fields={{
                dataSource: locationService,
                value: "_id",
                text: "name",
                parentValue: "parentId",
                hasChildren: "hasChild",
                query: locationQuery,
              }}
              select={(e) => {
                handleInputChange({
                  e,
                  args,
                  fieldIndex,
                  sectionIndex,
                  eachField,
                  section,
                  tableRowIndex,
                  source,
                  handleCustomFieldData,
                  name,
                  formObject,
                });
              }}
              name={name}
              showClearButton={false}
              close={(e) => {
                formObject?.current?.validate(name);
              }}
            />
          </div>
        );
      }
      if (lookUpAnsFieldType == "Teams") {
        const selectedValue = answerValue?.map((ans) => ans.id);
        fieldComponent = (
          <div>
            <MultiSelectComponent
              enabled={enabled}
              className={`field_${_id}`}
              dataSource={teamsService}
              data-msg-containerid={errorLabelId}
              value={selectedValue}
              fields={{ text: "name", value: "_id" }}
              width={source == formSectionType.FORM ? "50%" : "100%"}
              query={locationQuery}
              placeholder={placeholder ?? ""}
              removed={() => {
                formObject?.current?.validate(name);
              }}
              close={() => {
                formObject?.current?.validate(name);
              }}
              change={(e) => {
                const argsModified = {
                  isInteracted: e.isInteracted,
                  itemData: [
                    ...e.element.ej2_instances[0].sortedData.filter((item) =>
                      e.value.includes(item._id),
                    ),
                  ],
                };
                handleInputChange({
                  e: argsModified,
                  args,
                  fieldIndex,
                  sectionIndex,
                  eachField,
                  section,
                  tableRowIndex,
                  source,
                  handleCustomFieldData,
                  name,
                  formObject,
                });
              }}
              name={name}
              changeOnBlur={false}
            />
          </div>
        );
      }
    }
    if (fieldType == "number") {
      fieldComponent = (
        <NumericTextBoxComponent
          format="###.##"
          className={`field_${_id}`}
          disabled={!enabled}
          placeholder={placeholder ?? "Enter Value"}
          max={maxFieldRange ? +maxFieldRange : null}
          data-msg-containerid={errorLabelId}
          min={minFieldRange ? +minFieldRange : 0}
          value={answerValue ?? null}
          change={(e) => {
            handleInputChange({
              e,
              args,
              fieldIndex,
              sectionIndex,
              eachField,
              section,
              tableRowIndex,
              source,
              handleCustomFieldData,
              name,
              formObject,
            });
            formObject?.current?.validate(name);
          }}
          width={width}
          name={name}
        />
      );
    }
    if (fieldType == "users") {
      fieldComponent = (
        <div>
          {enabled && (
            <div
              onClick={() =>
                openAddUserModal({
                  eachField,
                  fieldIndex,
                  sectionIndex,
                  section,
                  source,
                  tableRowIndex,
                })
              }
              className="bg-button_light h-8 w-14 rounded-3xl justify-center flex cursor-pointer items-center"
            >
              <IconComponent name="UserProfile" />
            </div>
          )}
          <div className="flex flex-wrap gap-5 overflow-auto pt-5">
            {answerValue?.map((user) =>
              getUserCardAndChip({
                viewType,
                dataToDisplay,
                user,
                isCurrentUserAllowedToFill,
                isCurrentUserAllowedToModify,
                fieldIndex,
                sectionIndex,
                section,
                eachField,
                tableRowIndex,
                source,
              }),
            )}
          </div>
        </div>
      );
    }
    if (fieldType == "fileUpload") {
      let answerValue = eachField.answer?.value ?? [];
      if (source == formSectionType.TABLE_SECTION) {
        answerValue =
          section.tableDataSource[tableRowIndex]?.[_id]?.value ?? [];
      }
      fieldComponent = (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <input
              accept={eachField.allowedFileTypes.join(",")}
              type="file"
              id={`${questionId}${tableRowIndex}`}
              onChange={(e) =>
                handleFileUploadChange({
                  e,
                  sectionId,
                  eachField,
                  sectionIndex,
                  fieldIndex,
                  source,
                  tableRowIndex,
                })
              }
              style={{ display: "none" }}
            />
            <ButtonComponent
              onClick={() =>
                document.getElementById(`${questionId}${tableRowIndex}`).click()
              }
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled ? "var(--primary-text)" : "var(--system-colour-37"
                  }
                />
                <span>{placeholder}</span>
              </span>
            </ButtonComponent>
          </span>
          <span className="flex flex-col pr-3 overflow-auto gap-4 w-full h-full">
            {answerValue?.map((data, index) => (
              <span className="flex gap-3 border-solid border-2 border-button_light w-fit pr-2 rounded-lg pl-2 items-center">
                <span>{data.awsKey && handleFileOpen(data.awsKey, index)}</span>
                <span className="w-14 h-10 flex object-contain">
                  {" "}
                  {getFileIcon(data.type)}
                </span>
                <span>{data.name}</span>
                {enabled && (
                  <span className="w-8">
                    <IconComponent
                      cssClass="cursor-pointer"
                      onClick={() =>
                        handleRemoveUploadedFile({
                          awsKey: data.awsKey,
                          sectionIndex,
                          fieldIndex,
                          sectionId,
                          questionId,
                          tableRowIndex,
                          source,
                        })
                      }
                      name="close"
                    />
                  </span>
                )}
              </span>
            ))}
          </span>
        </div>
      );
    }
    if (fieldType == "image") {
      let answerValue = eachField.answer?.value ?? [];
      if (source == formSectionType.TABLE_SECTION) {
        answerValue =
          section.tableDataSource[tableRowIndex]?.[_id]?.value ?? [];
      }
      fieldComponent = (
        <div className="flex h-full w-full overflow-auto flex-col gap-5">
          <span>
            <ButtonComponent
              disabled={!enabled}
              buttonType={BUTTON_TYPES.BACKGROUND_BUTTON}
            >
              <span className="flex gap-1 items-center">
                <PlusIcon
                  color={
                    enabled ? "var(--primary-text)" : "var(--system-colour-37"
                  }
                />
                <span>{placeholder}</span>
              </span>
            </ButtonComponent>
          </span>
        </div>
      );
    }
    return (
      <>
        <span className="flex gap-2 flex-col">
          {fieldLabel ?? ""}
          {fieldComponent}
        </span>
        <div
          id={errorLabelId}
          className="mt-1 text-wrap w-full leading-normal"
        />
      </>
    );
  };

  const handleInputChange = ({
    e,
    args,
    eachField,
    maxFieldLimit,
    section = {},
    source = "",
    tableRowIndex,
    mobile,
    handleCustomFieldData,
    fieldIndex,
    sectionIndex,
    name,
    formObject = null,
  }) => {
    const {
      _id,
      fieldType,
      lookUpAnsFieldType,
      viewType = optionsViewType.LIST,
      minFieldRange,
      maxFieldRange,
    } = eachField;

    const isInteractionValid = _.get(e, "isInteracted", true);

    let answer = eachField.answer ?? {};

    if (source == formSectionType.TABLE_SECTION) {
      answer = section.tableDataSource[tableRowIndex]?.[_id] ?? {};
    }
    let answerValue;
    if (mobile) {
      if (fieldType == "single-line") {
        answerValue = e?.substring(0, maxFieldLimit)?.trim();
      }
      if (fieldType == "multi-line") {
        answerValue = e?.substring(0, maxFieldLimit)?.trim();
      }
      if (fieldType == "single-select") {
        answerValue = e;
      }
      if (fieldType == "multi-select") {
        if (viewType == optionsViewType.LIST) {
          if (e.checked) {
            if (answer?.value && answerValue.length) {
              answerValue = [...answer.value, e.value];
            } else {
              answerValue = [e.value];
            }
          } else {
            let tempAnswers = [...answer?.value];
            tempAnswers = tempAnswers.filter((ans) => ans != e.value);
            answerValue = [...tempAnswers];
          }
        } else if (viewType == optionsViewType.DROPDOWN) {
          if (answer.value) {
            const currentData = e[0];
            modifiedData = [...answer.value];
            const indexInvalue = answer.value.indexOf(currentData);
            if (indexInvalue !== -1) {
              modifiedData = answer.value.filter(
                (item) => item !== currentData,
              );
            } else {
              modifiedData.push(currentData);
            }
            answerValue = modifiedData;
          } else {
            answerValue = e;
          }
        }
      }
      if (fieldType == "date-time") {
        answerValue = e.getTime();
      }
      if (fieldType == "look-up") {
        e.map((data) => {
          if (answerValue && answerValue.length) {
            answerValue = [
              ...answerValue,
              { id: data, name: nameOfTeamLocationRespectToId[data] },
            ];
          } else {
            answerValue = [
              { id: data, name: nameOfTeamLocationRespectToId[data] },
            ];
          }
        });
      }
      if (fieldType == "number") {
        answerValue =
          Number(e.replace(/[^0-9]/g, "")) < minFieldRange
            ? Number(minFieldRange)
            : Number(e.replace(/[^0-9]/g, "")) > maxFieldRange
              ? maxFieldRange
              : Number(e.replace(/[^0-9]/g, ""));
      }
    } else if (
      fieldType == "single-select" ||
      fieldType == "multi-select" ||
      fieldType == "date-time" ||
      (fieldType == "multi-line" && ![undefined, null].includes(e.value)) ||
      e.isInteracted == true ||
      fieldType == "fileUpload"
    ) {
      if (fieldType == "single-line" && e.isInteracted == true) {
        answerValue = e.value?.trim();
      }
      if (fieldType == "multi-line") {
        answerValue = e.value?.trim();
      }
      if (fieldType == "single-select") {
        if (
          viewType == optionsViewType.LIST &&
          e.event.target.ej2_instances[0].properties.checked
        ) {
          answerValue = e.event.target.ej2_instances[0].properties.label;
        } else {
          answerValue = e.value;
        }
      }
      if (fieldType == "multi-select") {
        if (viewType === optionsViewType.LIST) {
          if (e.checked) {
            if (answer?.value && answer.value.length) {
              answerValue = [...answer.value, e.event.target.textContent];
            } else {
              answerValue = [e.event.target.textContent];
            }
          } else {
            let tempAnswers = answer?.value ? [...answer.value] : [];
            tempAnswers = tempAnswers.filter(
              (ans) => ans != e.event.target.textContent,
            );
            answerValue = [...tempAnswers];
          }
        } else if (e.name == "select" && e.isInteracted) {
          if (answer.value && answer.value.length) {
            answerValue = [...answer.value, e.itemData.value];
          } else {
            answerValue = [e.itemData.value];
          }
        } else if (e.name == "removed" && e.isInteracted) {
          let tempAnswers = [...answer.value];
          tempAnswers = tempAnswers.filter((ans) => ans != e.itemData.value);
          answerValue = [...tempAnswers];
        }
      }
      if (fieldType == "date-time") {
        if (eachField.selectionScope == "currentDateTime") {
          answerValue = Date.now();
        } else if (e.isInteracted == true && e.value) {
          answerValue = new Date(e.value).getTime();
        }
      }
      if (fieldType == "look-up" && e.isInteracted == true) {
        if (lookUpAnsFieldType == "Teams") {
          if (e.isInteracted) {
            const mappedItemData = e.itemData.map((item) => ({
              id: item._id,
              name: item.name,
            }));
            answerValue = mappedItemData;
          }
        }
        if (lookUpAnsFieldType == "Location") {
          if (e.action == "select") {
            if (answer.value && answer.value.length) {
              answerValue = [
                ...answer.value,
                { id: e.itemData.id, name: e.itemData.text },
              ];
            } else {
              answerValue = [{ id: e.itemData.id, name: e.itemData.text }];
            }
          }
          if (e.action == "un-select" && Array.isArray(answer.value)) {
            let tempAnswers = [...answer.value];
            tempAnswers = tempAnswers.filter(
              (location) => location.id != e.itemData.id,
            );
            answerValue = [...tempAnswers];
          }
        }
      }
      if (fieldType == "number" && e.isInteracted == true) {
        answerValue = e.value;
      }
      if (handleCustomFieldData && isInteractionValid) {
        handleCustomFieldData({
          args,
          eachField,
          answerValue,
          fieldIndex,
          sectionIndex,
          tableRowIndex,
          source,
        });
      }
    }
  };

  return {
    addValidationToInputFields,
    getFieldAnsComponent,
    handleInputChange,
    validateFormWithId,
  };
};

export const getNomenclatureField = (
  args,
  field,
  index,
  value,
  handleNomenclatureChange,
) => {
  if (field.type == "text" || field.type == "number") {
    const char = field.type == "text" ? "A" : "1";
    const placeholderText = field.allowedCharacters
      ? char.repeat(field.allowedCharacters)
      : `${char.repeat(3)}...`;
    const htmlAttributes = {};
    if (field.allowedCharacters) {
      htmlAttributes.maxlength = `${field.allowedCharacters}`;
    }
    const handleInput = (e) => {
      const maxLength = field.allowedCharacters;
      if (maxLength && e.value.length > maxLength) {
        e.event.target.value = e.value.slice(0, maxLength);
      }
    };

    return (
      <TextBoxComponent
        id={`${args.id}-${field.type}-${index}`}
        key={`${args.id}-${field.type}-${index}`}
        type={field.type}
        onClick={(e) => e.stopPropagation()}
        value={value}
        placeholder={placeholderText}
        className="flex-1"
        htmlAttributes={htmlAttributes}
        input={handleInput}
        onBlur={(e) => handleNomenclatureChange(e, args, field, index)}
      />
    );
  }
  if (field.type == "single select") {
    return (
      <DropDownListComponent
        id={`${args.id}-${field.type}-${index}`}
        key={`${args.id}-${field.type}-${index}`}
        className="flex-1"
        placeholder="Select an option"
        dataSource={field.options}
        value={value}
        onChange={(e) => handleNomenclatureChange(e, args, field, index)}
      />
    );
  }
};

export default useCustomFields;

export const getFileIcon = (fileType) => {
  if (fileType == "application/pdf") {
    return <IconComponent name="FilePDF" />;
  }
  if (fileType == "image/png") {
    return <IconComponent name="FilePNG" />;
  }
  if (fileType == "image/jpg") {
    return <IconComponent name="FileJPG" />;
  }
  if (fileType == "image/jpeg") {
    return <IconComponent name="FileJPG" />;
  }
};

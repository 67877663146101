import React, { FC, forwardRef, useRef } from "react";
import {
  MultiSelectComponentProps,
} from "../../interface";
import { MultiSelectComponent as SyncfusionMultiSelectComponent, MultiSelectModel } from "@syncfusion/ej2-react-dropdowns";
import { defaultMultiSelectComponentProps } from "../../constants/globalVariable";

import { createUseStyles } from "react-jss";
import {
  getMultiSelectStyle,
  getTextboxParentStyle,
} from "../../constants/globalFunctions";
const MultiSelectComponent: FC<MultiSelectComponentProps & MultiSelectModel> = forwardRef((props: MultiSelectComponentProps & MultiSelectModel, ref) => {
  /******************* 
@Purpose : MultiSelect Component
@Parameter : MultiSelectComponentProps
@Author : Techture
 *******************/

  const useStyles = createUseStyles({
    multiselectStyle: props.disableChipBackground ? undefined : getMultiSelectStyle(),
  });

  const multiSelectRef = ref || useRef(null)

  const parentTextBoxComponentStyle = getTextboxParentStyle(
    props.widthInPercentage as string,
    props.margin as string
  );
  const classes = useStyles() as any;
  return (
    <div style={parentTextBoxComponentStyle}>
      <SyncfusionMultiSelectComponent
        {...props}
        ref={multiSelectRef}
        cssClass={classes.multiselectStyle}
      ></SyncfusionMultiSelectComponent>
    </div>
  );
});
MultiSelectComponent.defaultProps = defaultMultiSelectComponentProps as any
export default MultiSelectComponent;

const { folderPermissions } = require('./../permissions/permissions.constant')
const { FOLDER_UPLOAD, FOLDER_DOWNLOAD, FOLDER_COMMENT, FOLDER_SHARE, FOLDER_STATUS_CHANGE, FOLDER_RENAME, FOLDER_DELETE } = folderPermissions()

function getGlobalRolesConstantArray () {
  const rolesData = getGlobalRolesConstant()
  const roles = []
  Object.keys(rolesData).forEach((key) => {
    roles.push({
      ...rolesData[key],
      name: key
    })
  })

  return roles
}

function getFolderRolesArray () {
  const folderRolesData = folderRoles()
  const roles = []
  Object.keys(folderRolesData).forEach((key) => {
    roles.push({
      ...folderRolesData[key],
      name: key
    })
  })

  return roles
}

function getGlobalRolesConstant () {
  return {
    PROJECT_MANAGER: {
      id: 1,
      description: 'Responsible for managing the project, including adding and removing other members, as well as creating spaces for collaboration',
      frontendName: 'Manager',
      permissions: [
        'USERS_ADD_TO_PROJECT',
        'USERS_REMOVE_FROM_PROJECT',
        'USERS_ADD_TO_SPACE',
        'USERS_REMOVE_FROM_SPACE', //
        'USERS_VIEW_IN_PROJECT',
        'EDIT_PROJECT_INFO', //
        'SPACES_ADD_TO_PROJECT',
        'SPACES_DELETE_FROM_PROJECT',
        'SPACES_EDIT',
        'SPACES_VIEW_IN_PROJECT',
        'TRANSMITTAL_CREATE',
        'FOLDER_ROOT_PERMISSION_CHANGE',
        'ROLES_VIEW_IN_PROJECT',
        'ROLES_ADD_TO_PROJECT',
        'ROLES_EDIT_IN_PROJECT',
        'ROLES_CHANGE_OF_USER_IN_PROJECT',
        'ROLES_DELETE_IN_PROJECT',
        'CONFIGURATION_SETTINGS_VIEW',
        'CONFIGURATION_SETTINGS_MODIFY',
        'SPECIFIC_CREATE_FORM_FLOW',
        'USERS_ADD_TO_VISIBILITY',
        'USERS_REMOVE_FROM_VISIBILITY',
        'PROJECT_SELECT_FORM_TEMPLATES',
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PROJECT'
    },
    PROJECT_EDITOR: {
      id: 2,
      description: 'A general user of the project who can collaborate on the project and create tasks, issues and upload files.',
      frontendName: 'Editor',
      permissions: [
        'USERS_VIEW_IN_PROJECT',
        'SPACES_ADD_TO_PROJECT',
        'SPACES_VIEW_IN_PROJECT',
        'TRANSMITTAL_CREATE',
        'ROLES_VIEW_IN_PROJECT',
        'CONFIGURATION_SETTINGS_VIEW',
        'CONFIGURATION_SETTINGS_MODIFY'
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PROJECT'
    },
    PROJECT_VIEWER: {
      id: 3,
      description: 'Can only view the project details and spaces that they are added to',
      frontendName: 'Viewer',
      permissions: [],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PROJECT'
    },
    PORTAL_SUPER_ADMIN: {
      id: 4,
      description: 'Responsible for managing the portal, including adding and removing other members, as well as change key portal information',
      frontendName: 'Super Admin',
      permissions: [
        'PROJECT_ADD_TO_PORTAL',
        'PROJECT_LIST_VIEW_IN_PORTAL',
        'PROJECT_ARCHIVE_OF_PORTAL',
        'PROJECT_INFORMATION_EDIT',
        'VIEW_ACCEPTED_COMPANIES',
        'VIEW_INVITED_COMPANIES',
        'PORTAL_REMOVE_COMPANIES',
        'PORTAL_ADD_COMPANIES',
        'ACCEPT_COMPANIES',
        'REJECT_COMPANIES',
        'EDIT_INFORMATION_OF_COMPANIES',
        'ASSIGN_PROJECT_TO_REGION',
        'REMOVE_PROJECT_FROM_REGION',
        'DELETE_REGION',
        'CREATE_REGION',
        'EDIT_REGION',
        'VIEW_LIST_OF_REGION',
        'CREATE_PORTAL_ROLES',
        'DELETE_PORTAL_ROLES',
        'VIEW_LIST_OF_PORTAL_ROLES',
        'EDIT_PORTAL_ROLES',
        'STATUS_CREATE_OF_PORTAL_ENTITIES',
        'STATUS_DELETE_OF_PORTAL_ENTITIES',
        'STATUS_EDIT',
        'STATUS_VIEW',
        'USERS_VIEW_ALL_IN_PORTAL',
        'USERS_ASSIGN_ROLE_TO_PROJECTS',
        'USERS_REMOVE_ACCESS_TO_PROJECTS',
        'USERS_PORTAL_ROLE_CHANGE',
        'USERS_PROJECT_ROLE_CHANGE',
        'ACCESS_PORTAL_SETTINGS',
        'ACCESS_PROJECTS_PART_OF',
        'CHANGE_PORTAL_INFORMATION'
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PORTAL'
    },
    PORTAL_ADMIN: {
      id: 5,
      description: 'Responsible for managing the portal, including adding and removing other members',
      frontendName: 'Admin',
      permissions: [
        'PROJECT_ADD_TO_PORTAL',
        'PROJECT_LIST_VIEW_IN_PORTAL',
        'PROJECT_ARCHIVE_OF_PORTAL',
        'PROJECT_INFORMATION_EDIT',
        'VIEW_ACCEPTED_COMPANIES',
        'VIEW_INVITED_COMPANIES',
        'PORTAL_ADD_COMPANIES',
        'ACCEPT_COMPANIES',
        'REJECT_COMPANIES',
        'EDIT_INFORMATION_OF_COMPANIES',
        'ASSIGN_PROJECT_TO_REGION',
        'REMOVE_PROJECT_FROM_REGION',
        'DELETE_REGION',
        'CREATE_REGION',
        'EDIT_REGION',
        'VIEW_LIST_OF_REGION',
        'CREATE_PORTAL_ROLES',
        'DELETE_PORTAL_ROLES',
        'VIEW_LIST_OF_PORTAL_ROLES',
        'EDIT_PORTAL_ROLES',
        'STATUS_CREATE_OF_PORTAL_ENTITIES',
        'STATUS_DELETE_OF_PORTAL_ENTITIES',
        'STATUS_EDIT',
        'STATUS_VIEW',
        'USERS_VIEW_ALL_IN_PORTAL',
        'USERS_ASSIGN_ROLE_TO_PROJECTS',
        'USERS_REMOVE_ACCESS_TO_PROJECTS',
        'USERS_PORTAL_ROLE_CHANGE',
        'USERS_PROJECT_ROLE_CHANGE',
        'ACCESS_PORTAL_SETTINGS',
        'ACCESS_PROJECTS_PART_OF',
        'CHANGE_PORTAL_INFORMATION'
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PORTAL'
    },
    PORTAL_MEMBER: {
      id: 6,
      description: 'A general user of the portal, who can perform most functions like creating projects etc',
      frontendName: 'Member',
      permissions: [
        'PROJECT_ADD_TO_PORTAL',
        'VIEW_LIST_OF_REGION',
        'VIEW_LIST_OF_PORTAL_ROLES',
        'STATUS_VIEW',
        'ACCESS_PORTAL_SETTINGS',
        'ACCESS_PROJECTS_PART_OF'
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PORTAL'
    },
    PORTAL_GUEST: {
      id: 7,
      description: 'Can only view the portal information and have access to the projects that they have been made part of',
      frontendName: 'Guest',
      permissions: [
        'ACCESS_PROJECTS_PART_OF'
      ],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_PORTAL'
    },
    SPACE_HEAD: {
      id: 8,
      description: 'Responsible for managing the space, including adding and removing other members',
      frontendName: 'Head',
      permissions: [],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_SPACE'
    },
    SPACE_MEMBER: {
      id: 9,
      description: 'A general user of the space, who can perform most functions like creating projects etc',
      frontendName: 'Member',
      permissions: [],
      status: 'ACTIVE',
      type: 'RESOURCE_TYPE_SPACE'
    }
  }
}

function folderRoles () {
  return {
    FOLDER_ADMIN: {
      id: 1,
      description: 'Can edit and share with others',
      frontendName: 'Full Access',
      code : "FULL_ACCESS",
      type: 'RESOURCE_TYPE_FOLDER',
      permissions: [
        FOLDER_UPLOAD.description,
        FOLDER_STATUS_CHANGE.description,
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description,
        FOLDER_SHARE.description,
        FOLDER_RENAME.description,
        FOLDER_DELETE.description
      ],
      permissionIds: [
        FOLDER_UPLOAD.id,
        FOLDER_STATUS_CHANGE.id,
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id,
        FOLDER_SHARE.id,
        FOLDER_RENAME.id,
        FOLDER_DELETE.id
      ]
    },
    FOLDER_EDITOR: {
      id: 2,
      description: 'Can upload, download, modify & view',
      frontendName: 'Can Edit',
      code: "CAN_EDIT",
      type: 'RESOURCE_TYPE_FOLDER',
      permissions: [
        FOLDER_UPLOAD.description,
        FOLDER_STATUS_CHANGE.description,
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description,
        FOLDER_RENAME.description,
        FOLDER_DELETE.description
      ],
      permissionIds: [
        FOLDER_UPLOAD.id,
        FOLDER_STATUS_CHANGE.id,
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id,
        FOLDER_RENAME.id,
        FOLDER_DELETE.id
      ]
    },
    FOLDER_DOWNLOADER: {
      id: 4,
      description: 'Can download & view',
      frontendName: 'Can Download',
      code : "CAN_DOWNLOAD",
      type: 'RESOURCE_TYPE_FOLDER',
      permissions: [
        FOLDER_DOWNLOAD.description,
        FOLDER_COMMENT.description
      ],
      permissionIds: [
        FOLDER_DOWNLOAD.id,
        FOLDER_COMMENT.id
      ]
    },
    FOLDER_VIEWER: {
      id: 6,
      description: 'Cannot edit, download or share with others',
      frontendName: 'Can View',
      code : "CAN_VIEW",
      type: 'RESOURCE_TYPE_FOLDER',
      permissions: [],
      permissionIds: []
    },
    FOLDER_NO_ACCESS: {
      id: 7,
      description: '',
      frontendName: 'No access',
      code : "NO_ACCESS",
      type: 'RESOURCE_TYPE_FOLDER',
      permissions: [],
      permissionIds: []
    }
  }
}

function getPermissionConstants () {
  const permissions = folderRoles()
  const obj = {}

  Object.values(permissions).map((role) => {
    obj[role.code] = role.id
  })
  return obj
}

module.exports = {
  getGlobalRolesConstantArray,
  getGlobalRolesConstant,
  folderRoles,
  getFolderRolesArray,
  getPermissionConstants
}

export const preSubmissionTypeDataSource = [
  { name: "Fill", value: "Fill" },
  { name: "Modify", value: "Modify" },
];
export const postSubmissionTypeDataSource = [
  { name: "Fill", value: "Fill" },
  { name: "Modify", value: "Modify" },
  { name: "Verify", value: "Verify" },
];
export const linkBoqSources = {
  INSPECTION_FORM: "inspectionForms",
  LINK_ITEMS: "linkItems",
};
export const formStatuses = {
  inProgress: "IN_PROGRESS",
  rejected: "REJECTED",
  approved: "APPROVED",
  submitted: "SUBMITTED",
};
export const formStatusFilterDataSource = [
  { name: "In Progress", value: formStatuses.inProgress },
  { name: "Rejected", value: formStatuses.rejected },
  { name: "Approved", value: formStatuses.approved },
  { name: "Submitted", value: formStatuses.submitted },
];

export const formSectionType = {
  FORM: "form",
  TABLE_SECTION: "tableSection",
};
export const getUserPermissionForForm = (
  inspectionData,
  userId,
  sectionIndex,
) => {
  const isCurrentUserAllowed =
    (inspectionData.selectedForm.template[sectionIndex].permissions[
      inspectionData.currentLevelOfForm
    ].Fill.includes(userId) ||
      inspectionData.selectedForm.template[sectionIndex].permissions[
        inspectionData.currentLevelOfForm
      ].Modify.includes(userId)) &&
    !inspectionData.isRejected &&
    !inspectionData.isApproved &&
    !inspectionData.isArchived.status;

  const currentDate = Date.now();
  const isDueDateExceded =
    inspectionData.dueDate && inspectionData.dueDate < currentDate;

  return isCurrentUserAllowed && !isDueDateExceded;
};
export const getFillPermissionForForm = (inspectionData, userId) => {
  const isCurrentUserAllowed =
    inspectionData.selectedForm.template[sectionIndex].permissions[
      inspectionData.currentLevelOfForm
    ].Fill.includes(userId) &&
    !inspectionData.isRejected &&
    !inspectionData.isApproved &&
    !inspectionData.isArchived.status;

  const currentDate = Date.now();
  const isDueDateExceded =
    inspectionData.dueDate && inspectionData.dueDate < currentDate;

  return isCurrentUserAllowed && !isDueDateExceded;
};
export const getModifyPermissionForForm = (inspectionData, userId) => {
  const isCurrentUserAllowed =
    inspectionData.selectedForm.template[sectionIndex].permissions[
      inspectionData.currentLevelOfForm
    ].Modify.includes(userId) &&
    !inspectionData.isRejected &&
    !inspectionData.isApproved &&
    !inspectionData.isArchived.status;

  const currentDate = Date.now();
  const isDueDateExceded =
    inspectionData.dueDate && inspectionData.dueDate < currentDate;

  return isCurrentUserAllowed && !isDueDateExceded;
};

export const getVerifyPermissionForForm = (inspectionData, userId) => {
  const isCurrentUserAllowed =
    inspectionData.selectedForm.template[sectionIndex].permissions[
      inspectionData.currentLevelOfForm
    ].Verify.includes(userId) &&
    !inspectionData.isRejected &&
    !inspectionData.isApproved &&
    !inspectionData.isArchived.status;

  const currentDate = Date.now();
  const isDueDateExceded =
    inspectionData.dueDate && inspectionData.dueDate < currentDate;

  return isCurrentUserAllowed && !isDueDateExceded;
};
export const getSectionPermission = ({
  section,
  inspectionData,
  userId,
  type,
}) => {
  const isCurrentUserAllowed =
    section.permissions[inspectionData.currentLevelOfForm][type].includes(
      userId,
    ) &&
    !inspectionData.isArchived.status &&
    !inspectionData.isRejected &&
    !inspectionData.isApproved;

  const currentDate = Date.now();
  const isDueDateExceded =
    inspectionData.dueDate && inspectionData.dueDate < currentDate;

  return isCurrentUserAllowed && !isDueDateExceded;
};

import { showToastNotification } from "./toast";
import axiosWrapper from "../services/axiosWrapper";

export function setTransmitalsData(data) {
  return {
    type: "SAVE_TRANSMITALS_DATA",
    data,
  };
}

export function setTransmitalMetadata(data) {
  return {
    type: "SAVE_TRANSMITALS_METADATA",
    data,
  };
}

export function setTransmittalAccess(data) {
  return {
    type: "SAVE_TRANSMITTAL_ACCESS",
    data,
  };
}

export function setTransmittalDataForReviews(data) {
  return {
    type: "SAVE_TRANSMITTAL_DATA_FOR_REVIEWS",
    data,
  };
}

export function setTransmittalReferences(data) {
  return {
    type: "SAVE_TRANSMITTAL_REFERENCES",
    data,
  };
}

export function setTransmittalVersions(data) {
  return {
    type: "SAVE_TRANSMITTAL_VERSIONS",
    data,
  };
}

export function mergeTransmitalsData(data) {
  return {
    type: "MERGE_TRANSMITALS_DATA",
    data,
  };
}

export async function saveTransmitalsData(data) {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/saveTransmittalsData`,
    data,
  );
}

export async function getTransmittalAttachedFiles(data) {
  return await axiosWrapper.post(
    `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/getFilesAttachedToTransmittal`,
    data,
  );
}

export function getSharedTransmittalData() {
  return (dispatch) => {
    axiosWrapper
      .get(
        `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/getSharedTransmittalData`,
      )
      .then((response) => {
        if (response.data.status) {
          dispatch(mergeTransmitalsData(response.data.data));
        }
      });
  };
}

export function getTransmittalAccess(data) {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/isTransmittal403`,
      data,
    );
    if (responseData.data.status) {
      dispatch(setTransmittalAccess(responseData.data));
    }
  };
}

export function getAllTransmittalVersions(data) {
  return async (dispatch) => {
    const responseData = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/transmittals/getTransmittalVersions`,
      data,
    );
    if (responseData.data) {
      dispatch(setTransmittalVersions(responseData.data));
    }
  };
}

export function getTransmittals(params) {
  return async (dispatch) => {
    let urlParams = "";
    let postData = {};

    if (params.byPortal) {
      urlParams = "/transmittals/getTransmittalsByPortal";
      postData = {
        portalId: params.portalId,
        value: params.value,
      };
    } else if (params.projectId) {
      urlParams = "/transmittals/getTransmittalsByProject";
      postData = {
        projectId: params.projectId,
      };
      if (params.filter) {
        postData.filter = params.filter;
      }
    } else if (params.transmittalVersionId) {
      urlParams = "/transmittals/getTransmittalDataById";
      postData = {
        transmittalId: params.transmittalVersionId,
      };
    }
    await axiosWrapper
      .post(`${process.env.REACT_APP_CUBE_BACKEND}${urlParams}`, postData)
      .then((response) => {
        if (response.data.status) {
          dispatch(setTransmitalsData(response.data.data));
          if (response.data.metadata) {
            dispatch(setTransmitalMetadata(response.data.metadata));
          }
        }
      });
  };
}

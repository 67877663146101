import { useState, useRef } from "react";

const useFormValidator = (initialRules = {}) => {
  const [error, setError] = useState({});
  const rulesRef = useRef(initialRules);

  const updateRules = (newRules) => {
    rulesRef.current = { ...rulesRef.current, ...newRules };
  };

  const validate = ({ name, value }) => {
    const fieldRules = rulesRef.current[name] || {};
    rulesRef.current = {
      ...rulesRef.current,
      [name]: {
        ...rulesRef.current[name],
        value,
      },
    };

    if (fieldRules.required) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        setError((prevState) => ({
          ...prevState,
          [name]: "This field is required",
        }));
        return;
      }
    }

    if (typeof value === "string" || typeof value === "number") {
      if (fieldRules.minLength && value.length < fieldRules.minLength) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Minimum ${fieldRules.minLength} characters required`,
        }));
        return;
      }
      if (fieldRules.maxLength && value.length > fieldRules.maxLength) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Maximum ${fieldRules.maxLength} characters allowed`,
        }));
        return;
      }
      if (fieldRules.minRange && Number(value) < fieldRules.minRange) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Minimum value should be ${fieldRules.minRange}`,
        }));
        return;
      }
      if (fieldRules.maxRange && Number(value) > fieldRules.maxRange) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Maximum value should be ${fieldRules.maxRange}`,
        }));
        return;
      }
    }

    if (Array.isArray(value)) {
      if (fieldRules.minSelection && value.length < fieldRules.minSelection) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Minimum ${fieldRules.minSelection} selection required`,
        }));
        return;
      }
      if (fieldRules.maxSelection && value.length > fieldRules.maxSelection) {
        setError((prevState) => ({
          ...prevState,
          [name]: `Maximum ${fieldRules.maxSelection} selection allowed`,
        }));
        return;
      }
    }

    if (fieldRules.minNoOfFiles && value.length < fieldRules.minNoOfFiles) {
      setError((prevState) => ({
        ...prevState,
        [name]: `Minimum ${fieldRules.minNoOfFiles} files required`,
      }));
      return;
    }
    if (fieldRules.maxNoOfFiles && value.length > fieldRules.maxNoOfFiles) {
      setError((prevState) => ({
        ...prevState,
        [name]: `Maximum ${fieldRules.maxNoOfFiles} files allowed`,
      }));
      return;
    }

    setError((prevState) => ({ ...prevState, [name]: "" }));
  };

  function validateAll() {
    Object.keys(rulesRef.current).forEach((name) =>
      validate({ name, value: rulesRef.current[name].value }),
    );
  }

  return { validate, error, updateRules, validateAll };
};

export default useFormValidator;

export function saveTransmital(state = [], action) {
  switch (action.type) {
    case "SAVE_TRANSMITALS_DATA":
      return action.data;
    case "MERGE_TRANSMITALS_DATA":
      return { ...state, ...action.data };
    default:
      return state;
  }
}

export function saveTransmitalMetadata(state = [], action) {
  switch (action.type) {
    case "SAVE_TRANSMITALS_METADATA":
      return action.data;
    default:
      return state;
  }
}

export function setTransmittalAccess(state = [], action) {
  switch (action.type) {
    case "SAVE_TRANSMITTAL_ACCESS":
      return action.data;
    default:
      return state;
  }
}

export function setTransmittalDataForReviews(state = null, action) {
  switch (action.type) {
    case "SAVE_TRANSMITTAL_DATA_FOR_REVIEWS":
      return action.data;
    default:
      return state;
  }
}

export function setTransmittalReferences(state = [], action) {
  switch (action.type) {
    case "SAVE_TRANSMITTAL_REFERENCES":
      return action.data;
    default:
      return state;
  }
}

export function setTransmittalVersions(state = [], action) {
  switch (action.type) {
    case "SAVE_TRANSMITTAL_VERSIONS":
      return action.data;
    default:
      return state;
  }
}

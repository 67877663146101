import { useEffect, useState, lazy, Suspense } from "react";
import {
  Route,
  useLocation,
  useNavigate,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import css from "./App.module.css";
import Header from "@Components/Header";
import { getUserDetails } from "./actions/userDetails";
import LicenceWarning from "./components/LicenceWarning/LicenceWarning";
import getHomePageUrl from "./services/hooks/getHomePageUrl";
import { getUserPermissions } from "./actions/userPermissions";
import { fetchLayout } from "./actions/layout";
import MinimizeFloatter from "@Components/MinimizeFloatter";
import { getPortalData } from "@Actions/portalSetting";
import * as Sentry from "@sentry/react";
import PaymentPlanWarning from "@Components/PaymentPlanWarning";
import OldBrowserWarning from "@Components/OldBrowserWarning";
import Notification from "./components/Notification";
import MobileDeviceRestrict from "@Components/MobileDeviceRestrict";
import { getCurrProjectData } from "./actions/breadcrumbsdata";
import FileUpload from "./components/FileManager/FileUpload/FileUpload";
import axiosWrapper from "@Services/axiosWrapper";
import { getPortalObject } from "./actions/portalDashboard.js";
import StickySidebar from "./components/StickySidebar";
import { setShowStickySidebar } from "./actions/stickySidebar.js";
import ErrorBoundary from "./ErrorBoundary.js";
import routes from "./routes";

const FeedbackToastComponent = lazy(
  () => import("./cubeComponent/html/feedbackToast"),
);
const FileSync = lazy(() => import("./components/FilesSync"));
const PortalExpired = lazy(
  () => import("@Components/PortalExpired/PortalExpired"),
);
const UserPortalAccessDenied = lazy(
  () => import("./components/UserPortalAccessDenied"),
);

function Router() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    licenceStatus,
    userDetails,
    // fileSyncState,
    showStickySidebar,
    fileUploadState,
    fileUploadProperties,
  } = useSelector((state) => state);

  const [isPortalExpired, setIsPortalExpired] = useState(false);
  const status = localStorage.getItem("isLogedIn");
  const location = useLocation();
  const navigate = useNavigate();
  const [renderHeader, setRenderHeader] = useState(false);
  const [renderHomePage, setRenderHomePage] = useState(false);
  const [homePageUrl] = getHomePageUrl();
  const [showUserPortalAccessDenied, setShowUserPortalAccessDenied] =
    useState(false);
  const portalData = useSelector((state) => state.portalData);
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [showLogoutError, setShowLogoutError] = useState("");
  const [showOldBrowserWarning, setShowOldBrowserWarning] = useState(false);

  const detectMobileDevice = () => {
    if (
      !["techm-prod", "techm-preprod", "techm-dev"].includes(
        process.env.REACT_APP_ENV,
      ) &&
      !window.location.host.includes("dpmsportal")
    ) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent,
        ) &&
        !window.location.href.includes("mobileExplorer")
      ) {
        setIsMobileDevice(true);
      } else {
        setIsMobileDevice(false);
      }
    }
  };

  useEffect(() => {
    const errorMessage = searchParams.get("errorMessage");
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("errorMessage");

    if (errorMessage) {
      setSearchParams(newSearchParams);
      setShowLogoutError(decodeURIComponent(errorMessage));
    }
  }, [searchParams.get("errorMessage")]);

  useEffect(() => {
    const projectId = searchParams.get("projectId");
    if (projectId) {
      dispatch(getCurrProjectData(projectId));
    }
  }, [searchParams.get("projectId")]);

  useEffect(() => {
    if (
      portalData.status == "EXPIRED" &&
      !location.pathname.includes("subscription") &&
      location.pathname != "/"
    ) {
      setIsPortalExpired(true);
    } else {
      setIsPortalExpired(false);
    }
  }, [portalData, location]);

  useEffect(() => {
    // TODO: Safe check in case url contains null. will remove after may 2024 if code stables
    if (
      location.pathname.includes("null") ||
      location.search.includes("null")
    ) {
      window.location.href = "/";
    }
  }, [location]);

  useEffect(() => {
    if (renderHomePage && homePageUrl) {
      // TODO: Copied from signin form page
      const searchParams = new URLSearchParams(location.search);

      let redirectUrl = homePageUrl;
      if (searchParams.get("redirectUrl")) {
        redirectUrl = decodeURIComponent(searchParams.get("redirectUrl"));
        try {
          const url = new URL(redirectUrl);
          if (url.origin == process.env.REACT_APP_CUBE_BACKEND) {
            window.location.href = redirectUrl;
          }
        } catch (e) {
          navigate(redirectUrl);
        }
      }
      navigate(redirectUrl);
    }
  }, [renderHomePage, homePageUrl]);

  const init = () => {
    if (status == "true") {
      dispatch(
        getUserPermissions({
          resourceType: ["RESOURCE_TYPE_PORTAL"],
        }),
      );
    }

    if (!location.pathname.includes("googleSigninButton")) {
      dispatch(fetchLayout());
      dispatch(getPortalData());
    }
  };

  useEffect(() => {
    // updateSupportChatProperties();
    init();
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_DSN && userDetails.id) {
      Sentry.setUser({ email: userDetails.email, id: userDetails.id });
    }
    if (userDetails.id) {
      dispatch(getPortalObject());
    }
  }, [userDetails]);

  const checkIfUserIsPartOfPortal = async () => {
    const response = await axiosWrapper.post(
      `${process.env.REACT_APP_CUBE_BACKEND}/accounts/isUserPartOfPortal`,
    );
    if (!response.data.status) {
      setShowUserPortalAccessDenied(true);
    }
  };

  const checkBrowserCompatibility = () => {
    if (
      typeof Array.prototype.at != "function" ||
      typeof Array.prototype.toSorted != "function"
    ) {
      setShowOldBrowserWarning(true);
    }
  };
  useEffect(() => {
    if (!userDetails.id && status == "true") {
      dispatch(getUserDetails());
      checkIfUserIsPartOfPortal();
    }
    checkBrowserCompatibility();
  }, []);

  useEffect(() => {
    detectMobileDevice();
  }, [location]);

  useEffect(() => {
    if (location.pathname != "/") {
      setShowLogoutError("");
    }
  }, [location.pathname]);

  const matchRoute = (pathname, path) => {
    const regex = new RegExp(`^/?${path.replace(/:[^\s/]+/g, "([\\w-]+)")}$`);
    return regex.test(pathname);
  };

  useEffect(() => {
    if (location.pathname === "/" && status === "true") {
      setRenderHomePage(true);
      return;
    }
    if (
      location.pathname !== "/" &&
      status !== "true" &&
      !location.pathname.includes("thank-you") &&
      !location.pathname.includes("googleSigninButton") &&
      !location.pathname.includes("googleLoginCallback") &&
      !location.pathname.includes("resetPassword") &&
      !location.pathname.includes("95789") &&
      !location.pathname.includes("signup") &&
      !location.pathname.includes("invitation") &&
      !location.pathname.includes("pdfExport")
    ) {
      navigate(
        `/?redirectUrl=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}${window.location.hash}`,
      );
      return;
    }

    const currentRoute = routes.find((route) =>
      route.path.some((path) => matchRoute(location.pathname, path)),
    );

    if (currentRoute?.showStickySidebar === false) {
      dispatch(setShowStickySidebar(false));
    }
    if (currentRoute?.showHeader === false) {
      setRenderHeader(false);
    } else {
      setRenderHeader(true);
    }
  }, [location.pathname]);

  return (
    <>
      {showLogoutError && (
        <div className="flex justify-around text-white p-1 bg-system_colour_3">
          {showLogoutError}
        </div>
      )}
      <Suspense fallback={<></>}>
        <FeedbackToastComponent />
      </Suspense>

      {showOldBrowserWarning && (
        <Suspense fallback={<></>}>
          <OldBrowserWarning />
        </Suspense>
      )}

      {isMobileDevice && (
        <Suspense fallback={<></>}>
          <MobileDeviceRestrict />
        </Suspense>
      )}

      <Suspense fallback={<></>}>
        <FileSync />
      </Suspense>
      <div
        className={`${
          !location.pathname.includes("pdfExport") && css.controlSection
        }`}
      >
        {!licenceStatus && <LicenceWarning />}
        <PaymentPlanWarning />
        {isPortalExpired == true && (
          <Suspense fallback={<></>}>
            <PortalExpired />
          </Suspense>
        )}
        {showUserPortalAccessDenied && (
          <Suspense fallback={<></>}>
            <UserPortalAccessDenied />
          </Suspense>
        )}
        {renderHeader && <Header />}
        <MinimizeFloatter />
        {userDetails.id && <Notification />}
        {(fileUploadState == "MAXIMISED" || fileUploadState == "MINIMISED") && (
          <FileUpload />
        )}

        <div className="flex overflow-auto h-full w-full">
          {showStickySidebar && <StickySidebar />}
          <div className="w-full h-full overflow-auto">
            <ErrorBoundary location={location}>
              <Routes>
                {routes.flatMap(({ path, element, exact = false }) =>
                  path.map((singlePath) => (
                    <Route
                      key={singlePath}
                      path={singlePath}
                      exact={exact}
                      element={<Suspense>{element}</Suspense>}
                    />
                  )),
                )}
              </Routes>
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </>
  );
}

export default Router;

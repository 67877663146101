export const SignTypeValues = {
  TYPE_NAME_INITIAL: "TYPE_NAME_INITIAL",
  DRAW_ON_SCREEN: "DRAW_ON_SCREEN",
  IMPORT_AS_IMAGE: "IMPORT_AS_IMAGE",
  EMBED_INSIDE_FILE: "EMBED_INSIDE_FILE",
  ATTACH_SIGNED_DOCUMENT: "ATTACH_SIGNED_DOCUMENT",
  IMPORT_PROFILE_SIGNATURE: "IMPORT_PROFILE_SIGNATURE",
};

export const SignTypes = [
  // {
  //   name: "Type Name or Initials",
  //   value: SignTypeValues.TYPE_NAME_INITIAL
  // },
  {
    name: "Draw On Screen",
    value: SignTypeValues.DRAW_ON_SCREEN,
  },
  // {
  //   name: "Import as image",
  //   value: SignTypeValues.IMPORT_AS_IMAGE
  // }, {
  //   name: "Embed inside file",
  //   value: SignTypeValues.EMBED_INSIDE_FILE
  // },
  {
    name: "Attach Signed Document",
    value: SignTypeValues.ATTACH_SIGNED_DOCUMENT,
  },
  {
    name: "Import Profile Signature",
    value: SignTypeValues.IMPORT_PROFILE_SIGNATURE,
  },
];
export const getTaskId = (taskData) => {
  let taskId;
  if (taskData?.WBSCode) {
    taskId = `${taskData?.WBSCode}`;
  } else if (taskData?.customTaskId) {
    taskId = `${taskData?.customTaskId}`;
  } else {
    const counter = taskData?.scheduleCounter ?? "0";
    taskId = `TSK-${String(counter).padStart(4, "0")}`;
  }
  return taskId;
};
export const defaultFeedbackToastComponentProps = {
  position: {
    X: "Right",
    Y: "Bottom",
  },
  timeOut: 3000,
  width: 400,
  content: "<div>Your message has been <b>sent</b> successful.</div>",
  title: "",
  isActionButton: false,
  actionBtnText: "UNDO",
};
